import type { MutationKey, QueryKey } from '@tanstack/react-query'

import { UUID_REGEX } from '@/utils'
import { getResponseErrorMessage } from '@/utils/api/interceptors'

/**
 * These error messages are generic and not useful for debugging.
 * We want to ignore them, and use the default error name instead.
 */
const UNUSEFUL_ERROR_NAMES = ['Error', 'AxiosError', 'API Error'] as (
  | string
  | undefined
)[]

function getErrorName(
  defaultName: 'QueryError' | 'MutationError',
  error?: Error,
) {
  if (typeof error === 'string') {
    return error
  }

  if (UNUSEFUL_ERROR_NAMES.includes(error?.name)) {
    return defaultName
  }

  return error?.name ?? defaultName
}

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) {
    return error.message
  }
  if (Array.isArray(error)) {
    return error.join(' ')
  }

  return String(error)
}

export function getResponseContext(response: any) {
  const responseData = response?.data

  if (!responseData) return null

  return {
    data: JSON.stringify(responseData, null, 2),
    'error.code': responseData.errorCode,
    'error.message': getResponseErrorMessage(responseData?.errorMessage),
  }
}

export function normalizeKey(queryKey?: QueryKey | MutationKey): string[] {
  if (!Array.isArray(queryKey)) return []

  return queryKey?.map((key) =>
    typeof key === 'string'
      ? UUID_REGEX.test(key as string)
        ? '{uuid}'
        : key
      : '{options}',
  )
}

export class QueryError extends Error {
  constructor(originalError?: Error) {
    const message = getErrorMessage(originalError)

    super(message)

    this.name = getErrorName('QueryError', originalError)
    this.cause = originalError
    this.stack = originalError?.stack
  }
}

export class MutationError extends Error {
  constructor(originalError?: Error) {
    const message = getErrorMessage(originalError)

    super(message)

    this.name = getErrorName('MutationError', originalError)
    this.cause = originalError
    this.stack = originalError?.stack
  }
}
