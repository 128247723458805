import type { ReactNode } from 'react'
import type { StyleProp, ViewStyle } from 'react-native'
import type { TextStyle } from 'react-native'

import { H3 } from '@/components/Text'

import {
  Container,
  Content,
  InnerTitle,
  InnerTitleContainer,
  TitleContainer,
  Wrapper,
} from './styled'

type Props = {
  title?: string
  action?: ReactNode
  innerTitle?: string
  isLoading?: boolean
  children?: ReactNode
  withMargin?: boolean
  transparent?: boolean
  withPadding?: boolean
  withBorderRadius?: boolean
  loadingElement?: ReactNode
  style?: StyleProp<ViewStyle>
  titleStyle?: TextStyle
  containerStyle?: ViewStyle
  contentStyle?: StyleProp<ViewStyle>
}

function Card(props: Props) {
  const {
    style,
    title,
    action,
    children,
    innerTitle,
    contentStyle,
    isLoading = false,
    withPadding = false,
    transparent = false,
    loadingElement = null,
    withBorderRadius = false,
    titleStyle,
    containerStyle,
    ...otherProps
  } = props

  return (
    <Wrapper role="banner" style={containerStyle} {...otherProps}>
      {Boolean(title || action) && (
        <TitleContainer>
          {Boolean(title) && (
            <H3 role="heading" style={titleStyle}>
              {title}
            </H3>
          )}
          {action}
        </TitleContainer>
      )}
      <Container
        $transparent={transparent}
        $withBorderRadius={withBorderRadius}
        $withPadding={withPadding}
        style={style}
      >
        {innerTitle ? (
          <InnerTitleContainer>
            <InnerTitle>{innerTitle.toUpperCase()}</InnerTitle>
          </InnerTitleContainer>
        ) : null}
        <Content $withPadding={withPadding} style={contentStyle}>
          {isLoading ? loadingElement : children}
        </Content>
      </Container>
    </Wrapper>
  )
}

export default Card
