import type { ResourcesConfig } from 'aws-amplify'
import { fetchAuthSession } from 'aws-amplify/auth'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'

import { identify } from '@/utils/analytics'
import { cognitoStorageInterface } from '@/utils/storage'
import { getHandoff, useAuthStore } from '@/utils/zustand'

const userPoolId = process.env.EXPO_PUBLIC_AWS_USER_POOL_ID as string
const userPoolClientId = process.env
  .EXPO_PUBLIC_AWS_USER_POOL_WEB_CLIENT_ID as string

const currentConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolClientId,
      userPoolId,
    },
  },
}

cognitoUserPoolsTokenProvider.setKeyValueStorage(cognitoStorageInterface)

/**
 * Listens to changes in the auth store to update the analytics identity
 */
useAuthStore.subscribe(() => void identify())

export async function getIdToken(): Promise<string | null> {
  try {
    const session = await fetchAuthSession()

    const idToken = session.tokens?.idToken?.toString()

    if (!idToken) {
      const handoff = getHandoff()

      if (handoff?.idToken) {
        return handoff.idToken
      }

      throw new Error('Missing ID Token')
    }

    return idToken
  } catch {
    if (typeof process !== 'undefined' && process.env.IS_STORYBOOK) {
      return 'mock-token'
    }

    return null
  }
}

export async function getIsAuthenticated(): Promise<boolean> {
  const token = await getIdToken()
  return Boolean(token)
}

export default currentConfig
