import type { RawAxiosRequestHeaders } from 'axios'

import { axiosInstance } from '@/utils/api'
import type {
  APIResponse,
  Application,
  Customer,
  Document,
  GetTasksData,
} from '@/utils/api/types'
import { getResponseData } from '@/utils/api/utils'
import { getIsAuthenticated } from '@/utils/auth'
import { appVersion, isDevelopment } from '@/utils/constants'
import { MissingCustomerId } from '@/utils/errors'
import { getCustomerId } from '@/utils/zustand'

export type CustomerMeData = { customerId: string; customers: string[] }

export type CreditBureauReport = APIResponse<{
  scoreVantage: string
  updatedTimestamp: string
  provider: string
  noHit: boolean
}>

export function getCustomerDocuments(
  customerId: string | null,
  params?: { documentType: string },
) {
  if (!customerId) throw new MissingCustomerId()

  return axiosInstance
    .get<APIResponse<Document[]>>(`/customers/${customerId}/documents`, {
      params,
    })
    .then(getResponseData)
}

export async function getCustomerSelf(idToken?: string) {
  const existingCustomerId = getCustomerId()

  if (existingCustomerId)
    return { customerId: existingCustomerId, customers: [existingCustomerId] }

  const isAuthenticated = await getIsAuthenticated()

  if (!isAuthenticated && !idToken) {
    throw new Error('Not authenticated')
  }

  const headers: RawAxiosRequestHeaders = {
    'x-custom-app-version': appVersion,
  }

  if (idToken) headers.Authorization = `Bearer ${idToken}`

  return axiosInstance
    .get<APIResponse<CustomerMeData>>('/customers/me', { headers })
    .then(getResponseData)
}

export function getCustomer(customerId: string | null) {
  if (!customerId) throw new MissingCustomerId()

  return axiosInstance
    .get<APIResponse<Customer>>(`/customers/${customerId}`)
    .then(getResponseData)
}

export function getApplications(
  customerId: string | null,
  params?: { unexpire?: boolean },
) {
  if (!customerId) throw new MissingCustomerId()

  return axiosInstance
    .get<
      APIResponse<Application[]>
    >(`/autoFlow/customers/${customerId}/applications`, { params })
    .then(getResponseData)
}
export function getCreditReport(customerId: string | null) {
  if (!customerId) throw new MissingCustomerId()

  return axiosInstance
    .get<CreditBureauReport>(`/customers/${customerId}/creditBureauReports`)
    .then((response) => {
      const data = getResponseData(response)

      if (!data || !data.updatedTimestamp) throw new Error('No credit score')

      return data
    })
}

export type GetCustomerTaskResponse = APIResponse<GetTasksData>

export function getCustomerTasks(customerId: string | null) {
  if (!customerId) {
    // eslint-disable-next-line no-console
    if (isDevelopment) console.error('Missing customerId')
    throw new MissingCustomerId()
  }

  return axiosInstance
    .get<GetCustomerTaskResponse>(`/customers/${customerId}/tasks`)
    .then(getResponseData)
}

export function getCustomerReferralLink(customerId: string | null) {
  if (!customerId) throw new MissingCustomerId()

  return axiosInstance
    .get<APIResponse<string>>(`/customers/${customerId}/referralLink`)
    .then(getResponseData)
}
