import { FontSize } from '@/utils/constants/theme'

export type TextWeight =
  | 'bold'
  | 'medium'
  | 'normal'
  | 'semi-bold'
  | '600'
  | '700'

export enum FontWeights {
  Normal = '400',
  Medium = '500',
  SemiBold = '600',
  Bold = '700',
}

export const DEFAULT_FONT_WEIGHT = FontWeights.Medium
export const DEFAULT_FONT_FAMILY = 'Spezia-Medium'

export function getFontFamily(weight?: string) {
  if (String(weight) === FontWeights.Normal || weight === 'normal')
    return 'Spezia-Regular'
  if (String(weight) === FontWeights.Medium || weight === 'medium')
    return 'Spezia-Medium'
  if (String(weight) === FontWeights.SemiBold || weight === 'semi-bold')
    return 'Spezia-SemiBold'
  if (weight === 'bold' || String(weight) === FontWeights.Bold)
    return 'Spezia-Bold'
  return DEFAULT_FONT_FAMILY
}

export function capitalize<T extends string>(str: T): Capitalize<T> {
  return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>
}

export function getFontSize({
  size = 'medium',
  multiplier = 1,
}: {
  size?: number | Lowercase<keyof typeof FontSize>
  multiplier?: number
}) {
  const sizeValue =
    typeof size === 'number'
      ? size
      : typeof size === 'string' && FontSize[capitalize(size)].n
        ? FontSize[capitalize(size)].n
        : FontSize.Medium.n

  return `${sizeValue * multiplier}px` as const
}

export function getFontWeight(weight: number | TextWeight | FontWeights) {
  if (typeof weight === 'number') return weight
  if (!isNaN(Number(weight))) return Number(weight)
  return String(
    FontWeights[capitalize(weight) as keyof typeof FontWeights] ??
      DEFAULT_FONT_WEIGHT,
  )
}
