export enum SupportedLanguage {
  EnUs = 'en-US',
  EsUs = 'es-US',
}

export function getSafeLanguage(language: string) {
  if (language.toLowerCase().includes('es')) {
    return SupportedLanguage.EsUs
  }

  return SupportedLanguage.EnUs
}
