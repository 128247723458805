import styled from 'styled-components/native'

export const ScrollView = styled.ScrollView`
  flex: 1;
  max-height: 100%;
  min-height: 200px;
  width: 100%;
`

export const KeyboardAvoidingView = styled.KeyboardAvoidingView<{
  $backgroundColor?: string
}>`
  flex: 1;
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ?? theme.colors.backgroundContent};
`
