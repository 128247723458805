import * as Location from 'expo-location'
import { nanoid } from 'nanoid/non-secure'

import type { Customer } from '@/utils/api/types'

import { US_PHONE_LENGTH } from './constants/masks'
import { SupportedLanguage } from './language'

const formatter = new Intl.NumberFormat(SupportedLanguage.EnUs, {
  currency: 'USD',
  style: 'currency',
})

export const REMOVE = new String('')

export const UUID_REGEX =
  /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/i

export const WEBFLOW_COLLECTION_ID_REGEX = /[a-z0-9]{24}/gi

/**
 * Does nothing
 */
export const noop = () => undefined

/**
 * Builds a person's name based on available data
 */
export function getHolderName(customerData?: Customer): string {
  if (!customerData || !customerData.givenName) return ''

  const givenName = customerData.givenName || ''
  const middleName = customerData.middleName
    ? ` ${customerData.middleName}`
    : ''
  const familyName = customerData.familyName
    ? ` ${customerData.familyName}`
    : ''

  return `${givenName}${middleName}${familyName}`
}

export function getAddress(customerData?: Customer, index = 0) {
  const address = customerData?.addresses?.[index]

  return address
}

/**
 * Gets boolean value taking into account different formats
 */
export function getBool(value: any): boolean {
  if (value === 'true') return true
  if (value === 'false') return false
  if (value === '0') return false
  if (value === '1') return true
  return Boolean(value)
}

export function isEmpty(obj: any) {
  if (obj === null) return true
  if (obj === undefined) return true
  if (obj === '') return true
  if (obj === REMOVE) return true
  return Object.keys(obj).length === 0
}

export function getCleanDecimal(text: string) {
  return text.replace(/[^0-9.]/g, '')
}

export function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = String(phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

export const MAX_PHONE_LENGTH = 15

export function isFunction(argument: any) {
  return argument instanceof Function || typeof argument === 'function'
}

export function formatCurrency(
  value?: number | null,
  options: {
    dollarSymbol?: boolean
    ceil?: boolean
    omitZeroDecimals?: boolean
  } = {
    dollarSymbol: true,
    omitZeroDecimals: true,
  },
) {
  const { ceil, dollarSymbol = true, omitZeroDecimals = true } = options
  if (value === undefined || value === null) return ''

  let numberValue = value

  if (ceil) {
    numberValue = Math.ceil(Number(numberValue))
  }

  let formattedValue = formatter.format(numberValue)

  if (omitZeroDecimals) {
    formattedValue = formattedValue.replace(/\.00$/, '')
  }

  if (!dollarSymbol) {
    formattedValue = formattedValue.replace('$', '')
  }

  return formattedValue
}

export function getServerFormat(
  obj: Record<string, any> = {},
  keyPrefix = '',
): Record<'path' | 'value', string>[] {
  const entries: Record<'path' | 'value', string>[] = []

  const objectFromEntries = Object.entries(obj ?? {})

  if (objectFromEntries.length === 0) return entries

  for (const [path, value] of objectFromEntries) {
    let currentPath

    if (keyPrefix) {
      // Verify if the path is a numeric array index
      if (!isNaN(Number(path))) {
        currentPath = `${keyPrefix}[${path}]`
      } else {
        currentPath = `${keyPrefix}.${path}`
      }
    } else {
      currentPath = path
    }

    // Since null and undefined are objects, add additional check
    if (typeof value === 'object' && value !== null && value !== undefined) {
      entries.push(...getServerFormat(value, currentPath))
    } else {
      entries.push({
        path: currentPath,
        value,
      })
    }
  }

  return entries
}

// https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path?noredirect=1&lq=1
export function nestedGet(object: any, s: string): any {
  if (!object) return undefined
  if (s === '') return object

  s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  s = s.replace(/^\./, '') // strip a leading dot
  const propertyName = s.split('.')

  for (let index = 0, n = propertyName.length; index < n; ++index) {
    const value = propertyName[index]
    if (!value || !object) return

    if (value in object) {
      object = object[value]
    } else {
      return
    }
  }
  return object
}

export function getRandomPassword(): string {
  // ? and 0 and special and numeric characters required by cognito policy
  return `?0${nanoid()}`
}

export async function getPostalCodeFromLocation({
  onError,
}: {
  onError?: (error: Error) => void
}): Promise<any> {
  const { status } = await Location.requestForegroundPermissionsAsync()

  if (status !== 'granted') {
    onError?.(new Error('Permission not granted'))
  }

  const { coords } = await Location.getCurrentPositionAsync()

  const { latitude, longitude } = coords

  const [address] = await Location.reverseGeocodeAsync({
    latitude,
    longitude,
  })

  return address?.postalCode
}

/**
 * This function  will return a phone number without the country code
 * spaces, dashes or any other non-number characters.
 * It will also prefix the country code if passed.
 */
export function getPhoneNumber(value?: string, prefix?: string) {
  if (!value) return ''

  // Strip +1 from the phone number or (space)1 since + can be interpreted as a space
  let cleanValue = String(value || '').replace(/^[+ ]1/, '')
  // Remove letters and non-phone number related characters
  cleanValue = getOnlyNumbers(cleanValue)
  // Return first 10 digits
  cleanValue = cleanValue.slice(0, US_PHONE_LENGTH)

  if (prefix) {
    return `${prefix}${cleanValue}`
  }

  return cleanValue
}

export function getOnlyNumbers(value?: string) {
  return String(value || '').replace(/\D/g, '')
}

export function getIsNumeric(str: unknown) {
  if (typeof str === 'number') return true
  if (typeof str !== 'string') return false
  return !isNaN(str as any) && !isNaN(parseFloat(str))
}

/**
 * Returns the passed string with the first letter capitalized
 */
export function capitalize(string: string) {
  return (string[0] ? string[0].toUpperCase() : '') + string.slice(1)
}

export function groupBy(xs: any[], key: string): { [key: string]: any[] } {
  return xs.reduce((rv, x) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export function isNil(val: any): boolean {
  return val === null || val === undefined
}

export function getWordCount(text: string) {
  if (!text || text.trim() === '') return 0
  return text.trim().split(/\s+/).length
}

/**
 * Returns a new object with the specified keys omitted
 * @param object
 * @param keys
 */
export function omit<
  T extends {
    [key: string]: unknown
  },
  K extends keyof T,
>(object: T, keys: K[]): Omit<T, K> {
  const omitted: Partial<T> = {}
  Object.keys(object).forEach((key) => {
    if (!keys.includes(key as K)) {
      omitted[key as K] = object[key] as T[K]
    }
  })
  return omitted as Omit<T, K>
}
