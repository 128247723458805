import type { KeyValueStorageInterface } from 'aws-amplify/utils'
import { MMKV } from 'react-native-mmkv'

export const utilStorage = new MMKV({ id: 'mmkv.temp' })
export const zustandAuthStorage = new MMKV({ id: 'mmkv.zustand.auth' })
export const queryStorage = new MMKV({ id: 'mmkv.query' })

export const cognitoStorageInterface: KeyValueStorageInterface = {
  clear: () => Promise.resolve(zustandAuthStorage.clearAll()),
  getItem: (key) => Promise.resolve(zustandAuthStorage.getString(key) ?? null),
  removeItem: (key) => Promise.resolve(zustandAuthStorage.delete(key)),
  setItem: (key, value) => Promise.resolve(zustandAuthStorage.set(key, value)),
}

export const queryStorageInterface = {
  setItem: (key, value) => {
    queryStorage.set(key, value)
  },
  getItem: (key) => {
    const value = queryStorage.getString(key)
    return value === undefined ? null : value
  },
  removeItem: (key) => {
    queryStorage.delete(key)
  },
}
