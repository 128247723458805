import { Platform } from 'react-native'

import styled, { css } from 'styled-components/native'

import { getFontScale } from '@/utils/accessibility'
import { CornerRadius, FontSize, Space } from '@/utils/constants/theme'

import type { TextWeight } from './utils'
import {
  DEFAULT_FONT_WEIGHT,
  FontWeights,
  getFontFamily,
  getFontSize,
  getFontWeight,
} from './utils'

const HEADING_LINE_HEIGHT = 1.3

type TextProps = {
  /** @default theme.colors.text */
  color?: string
  /** Show text element as a placeholder */
  skeleton?: boolean
  /** @default 'left' */
  align?: 'center' | 'left' | 'right'
  /** @default 'medium' */
  size?: number | Lowercase<keyof typeof FontSize>
  /** @default FontWeights.Medium */
  weight?: TextWeight
}

const Text = styled.Text<TextProps>`
  ${({ weight = DEFAULT_FONT_WEIGHT }) =>
    Platform.OS === 'web'
      ? `font-family: ${getFontFamily(weight)};`
      : undefined};
  color: ${({ theme, color = theme.colors?.text }) => color};
  text-align: ${({ align = 'left' }) => align};
  font-weight: ${({ weight = DEFAULT_FONT_WEIGHT }) => getFontWeight(weight)};
  font-size: ${getFontSize};
  line-height: ${getFontSize};
  ${Platform.OS === 'web' ? 'word-break: break-word;' : undefined}

  ${({ skeleton, theme, size }) =>
    skeleton &&
    css`
      background-color: ${theme.colors.backgroundContent};
      overflow: hidden;
      border-radius: ${CornerRadius.Small.px};
      min-width: 20px;
      padding: 0 20px;
      min-height: ${getFontSize({ size })};
    `}
`

export const H1 = styled(Text).attrs((props) => ({
  ...props,
  size: 'xxxlarge',
  weight: 'bold',
}))`
  line-height: ${({ size }) =>
    getFontSize({ multiplier: HEADING_LINE_HEIGHT, size })};
`

export const H2 = styled(Text).attrs((props) => ({
  ...props,
  size: 'xxlarge',
  weight: 'bold',
}))`
  line-height: ${({ size }) =>
    getFontSize({ multiplier: HEADING_LINE_HEIGHT, size })};
`

export const H3 = styled(Text).attrs((props) => ({
  ...props,
  size: 'large',
  weight: 'semi-bold',
}))`
  line-height: ${({ size }) =>
    getFontSize({ multiplier: HEADING_LINE_HEIGHT, size })};
`

export const H4 = styled(Text).attrs((props) => ({
  ...props,
  size: 'large',
  weight: 'bold',
}))`
  line-height: ${({ size }) =>
    getFontSize({ multiplier: HEADING_LINE_HEIGHT, size })};
`

export const H5 = styled(Text).attrs((props) => ({
  size: 'large',
  weight: 'semi-bold',
  ...props,
}))`
  line-height: ${({ size }) =>
    getFontSize({ multiplier: HEADING_LINE_HEIGHT, size })};
`

export const S1 = styled(Text).attrs((props) => ({
  size: 'small',
  weight: 'bold',
  ...props,
}))`
  line-height: ${({ size }) =>
    getFontSize({ multiplier: HEADING_LINE_HEIGHT, size })};
`

export const S2 = styled(Text).attrs((props) => ({
  ...props,
  size: 'small',
  weight: 'semi-bold',
}))`
  line-height: ${FontSize.Small.n * HEADING_LINE_HEIGHT}px;
`

export const S3 = styled(Text).attrs((props) => ({
  ...props,
  size: 'medium',
  weight: 'bold',
}))`
  line-height: ${({ size }) =>
    getFontSize({ multiplier: HEADING_LINE_HEIGHT, size })};
`

export const P = styled(Text)`
  line-height: ${({ size }) => getFontSize({ multiplier: 1.45, size })};
`

export const B = styled(Text)`
  font-weight: ${FontWeights.Bold};
`

export const FootNote = styled(Text).attrs((props) => ({
  ...props,
  weight: FontWeights.Normal,
}))<any>`
  font-size: ${FontSize.Small.px};
  line-height: 17px;
`

export const Link = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${FontSize.Small.px};
  line-height: 17px;
  font-weight: ${FontWeights.SemiBold};
`

/**
 * Input Texts
 */

export const TextError = styled.Text`
  color: ${({ theme }) => theme.colors.error};
  position: absolute;
  bottom: -${
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      14 * getFontScale()
    }px;
  font-size: 11px;
  font-weight: ${FontWeights.Bold};
  padding: 0 5px;
  pointer-events: none;
`

export const TextWarning = styled(TextError)`
  color: ${({ theme }) => theme.colors.warning};
`

export const InputLabel = styled(P).attrs(
  ({ weight = 'semi-bold', size = 'large', ...props }: any) => ({
    size,
    weight,
    ...props,
  }),
)`
  ${Platform.OS === 'web' && 'white-space: pre-line;'}
`

export const InputFloatingLabel = styled(InputLabel)<{ $isFloating?: boolean }>`
  position: absolute;
  background-color: ${({ $isFloating, theme }) =>
    $isFloating ? undefined : theme.colors.background};
  z-index: 10;
  color: ${({ theme }) => theme.colors.text};
  height: auto;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: left;
  vertical-align: center;
  max-width: 100%;

  ${({ $isFloating }) =>
    $isFloating
      ? css`
          font-size: 10px;
          height: auto;
          left: 10px;
          margin-top: ${Space.Xxsmall.px};
          padding: 0;
          top: 0;
        `
      : css`
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: flex-start;
          left: 6px;
          margin: 0;
          padding: 0 10px;
          top: 0;
          width: 85%;
        `}
`

export const LabelText = styled.Text<{ $isFloating?: boolean }>`
  color: ${({ theme }) => theme.colors.label};
  font-size: ${FontSize.Small.px};

  ${({ $isFloating }) =>
    $isFloating &&
    css`
      font-weight: ${FontWeights.SemiBold};
      margin: 0;
      font-size: 9px;
    `};
`

export default Text
