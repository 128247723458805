import styled from 'styled-components/native'

import { Space } from '@/utils/constants/theme'

export const ContentContainer = styled.View<{ $withPadding?: boolean }>`
  flex: 1;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  padding: ${({ $withPadding }) => ($withPadding ? Space.Medium.px : 0)};
  background-color: ${({ theme }) => theme.colors.backgroundContent};
`

export const Wrapper = styled.ScrollView`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.backgroundContent};
`
