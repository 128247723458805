import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import { axiosInstance } from '@/utils/api'
import { DocumentType } from '@/utils/api/tasks'
import type { APIResponse, Document, Product } from '@/utils/api/types'
import { getResponseData } from '@/utils/api/utils'
import { MissingOwnerId, MissingProductId } from '@/utils/errors'
import { sortByUpdatedTimestamp } from '@/utils/sort'
import { useCustomerId } from '@/utils/zustand'

export const productKeys = {
  all: ['products'] as const,
  detail: (args: any) => [...productKeys.all, 'detail', args] as const,
  product: (productId: string) =>
    [...productKeys.all, 'detail', productId] as const,
  productDocuments: (productId: string, args: any) =>
    [...productKeys.product(productId), 'documents', args] as const,
  productReceipts: (productId: string) =>
    [...productKeys.product(productId), 'receipts'] as const,
  endorsementEligibility: (productId: string) =>
    [...productKeys.product(productId), 'endorsementEligibility'] as const,
}

export function getProducts(ownerId: string | null) {
  if (!ownerId) {
    throw new MissingOwnerId()
  }

  return axiosInstance
    .get<APIResponse<Product[]>>(`/products?ownerId=${ownerId}`)
    .then((response) => {
      const data = getResponseData(response)
      if (!data) return null

      try {
        return data.sort(
          (a, b) =>
            new Date(b.createdTimestamp as string).getDate() -
            new Date(a.createdTimestamp as string).getDate(),
        )
      } catch {
        return data
      }
    })
}

export function getProduct(productId: string) {
  if (!productId) throw new MissingProductId()

  return axiosInstance
    .get<APIResponse<Product>>(`/products/${productId}`)
    .then(getResponseData)
}

export function getProductDocuments(
  productId: string,
  params: { type?: string | DocumentType; productType?: string },
) {
  if (!productId) throw new MissingProductId()

  return axiosInstance
    .get<APIResponse<Document[]>>(`/products/${productId}/documents`, {
      params,
    })
    .then(getResponseData)
    .then((data) => data?.sort(sortByUpdatedTimestamp))
}

export function getReceipts(paymentOrderId: string) {
  return axiosInstance
    .get<
      APIResponse<{ preSignedGetUrl: string }>
    >(`/payments/orders/${paymentOrderId}/receipts`)
    .then((response) => {
      const data = getResponseData(response)
      if (!data) throw new Error('No receipt found')
      return data
    })
}

export enum EligibilityReason {
  ProductStatus = 'insurance.auto.changeRequest.invalid.productStatus',
  DaysDelinquent = 'insurance.auto.changeRequest.endorsement.eligibility.invalid.daysDelinquent',
  LastPaymentDate = 'insurance.auto.changeRequest.endorsement.eligibility.required.lastPaymentDate',
  TotalAmountPaid = 'insurance.auto.changeRequest.endorsement.eligibility.required.totalAmountPaid',
}

export function getEndorsementEligibility(productId: string): Promise<{
  isEndorsementEligible: boolean
  reason?: EligibilityReason
}> {
  return axiosInstance
    .get<APIResponse<{ isEndorsementEligible: true }>>(
      `/insurances/auto/products/${productId}/changeRequests/checkEligibility`,
    )
    .then(getResponseData)
    .catch((error) => {
      return {
        isEndorsementEligible: false,
        reason: Object.values(EligibilityReason).find((reason) =>
          error.message.includes(reason),
        ),
      }
    })
}

export function useReceipts(
  paymentOrderId: string,
  options?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getReceipts>>, AxiosError>
  >,
) {
  return useQuery({
    queryFn: () => getReceipts(paymentOrderId),
    queryKey: productKeys.productReceipts(paymentOrderId),
    ...options,
    enabled: options?.enabled !== false && Boolean(paymentOrderId),
  })
}

export function useProductDocuments(
  {
    productId,
    type = `${DocumentType.InsurancePolicyAuto},${DocumentType.InsurancePolicyAutoEvidence},${DocumentType.InsurancePremiumFinanceLoanContract},${DocumentType.InsurancePremiumFinanceNotice}`,
    productType = `LOAN_PREMIUM_FINANCE,INSURANCE_AUTO`,
  }: { productId: string } & Parameters<typeof getProductDocuments>[1],
  options?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getProductDocuments>>, AxiosError>
  >,
) {
  const args = { productType, type }

  return useQuery({
    queryFn: () => getProductDocuments(productId, args),
    queryKey: productKeys.productDocuments(productId, args),
    ...options,
  })
}

export function useProduct(productId: string) {
  return useQuery({
    queryFn: () => getProduct(productId),
    queryKey: productKeys.product(productId),
  })
}

/**
 * This hook will return fronted-sorted products by createdTimestamp
 */
export function useProducts(
  options?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, AxiosError>
  >,
) {
  const customerId = useCustomerId()

  return useQuery({
    queryFn: () => getProducts(customerId),
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: productKeys.all,
    ...options,
  })
}

export function useEndorsementEligibility(
  productId: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getEndorsementEligibility>>,
    AxiosError
  >,
) {
  return useQuery({
    queryFn: () => getEndorsementEligibility(productId),
    queryKey: productKeys.endorsementEligibility(productId),
    ...options,
  })
}
