import styled from 'styled-components/native'

import Text from '@/components/Text'
import { FontWeights } from '@/components/Text/utils'

import { CornerRadius, Space } from '@/utils/constants/theme'

export const Content = styled.View<{ $withPadding: boolean }>`
  padding: ${({ $withPadding }) => ($withPadding ? Space.Medium.px : '0')};
`

export const TitleContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const InnerTitleContainer = styled.View`
  width: 100%;
  padding: ${Space.Medium.px};
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-right-radius: ${CornerRadius.Large.px};
  border-top-left-radius: ${CornerRadius.Large.px};
  overflow: hidden;
`

export const InnerTitle = styled(Text)`
  color: white;
  text-align: center;
  font-weight: ${FontWeights.Bold};
  letter-spacing: 1px;
`

export const Container = styled.View<{
  $withPadding: boolean
  $transparent: boolean
  $withBorderRadius?: boolean
}>`
  background-color: ${({ theme, $transparent }) =>
    $transparent ? 'transparent' : theme.colors.background};
  border-radius: ${({ $withPadding, $withBorderRadius }) =>
    $withPadding || $withBorderRadius ? CornerRadius.Large.px : '0'};
  box-shadow: ${({ $transparent }) =>
    $transparent ? 'none' : `0 5px 5px rgba(0, 0, 0, 0.025)`};
`

export const Wrapper = styled.View`
  gap: ${Space.Xsmall.px};
`
