import { Suspense, useEffect, useState } from 'react'
import type { ComponentProps, ReactNode } from 'react'
import { View } from 'react-native'

import { useTranslation } from 'react-i18next'

import { LoadingContainer } from '@/screens/NewApplication/styled'

import Button from '@/components/Button'
import Text from '@/components/Text'

import Spinner from './LottieSpinner/lazy'

type Props = {
  style?: ComponentProps<typeof LoadingContainer>['style']
  message?: string
  children?: ReactNode
  hideActivityIndicator?: boolean
  /**
   * Timeout in seconds until fallback message is shown
   * @format seconds
   */
  timeout?: number | false
  onPress?: () => void
}

function LoadingScreen({
  style,
  message,
  onPress,
  children,
  timeout = false,
  hideActivityIndicator = false,
}: Props) {
  const { t } = useTranslation()

  const [isTimedOut, setIsTimedOut] = useState(false)
  const [showActivityIndicator, setShowActivityIndicator] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowActivityIndicator(true)
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, 500)

    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    if (!timeout) return undefined

    const timeoutId = setTimeout(() => {
      setIsTimedOut(true)
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, timeout * 1000)

    return () => clearTimeout(timeoutId)
  }, [timeout])

  return (
    <LoadingContainer style={style}>
      {Boolean(showActivityIndicator) && (
        <>
          {!hideActivityIndicator && (
            <Suspense fallback={null}>
              <Spinner />
            </Suspense>
          )}
          {typeof children === 'object' ? (
            children
          ) : message && !isTimedOut ? (
            <Text color="#636159" style={{ marginTop: 10 }} weight="bold">
              {message}
            </Text>
          ) : null}
          {Boolean(isTimedOut) && (
            <View style={{ alignItems: 'center', marginTop: 8 }}>
              <Text
                color="#636159"
                style={{ marginBottom: 8, textAlign: 'center' }}
                weight="bold"
              >
                {t('screens.loading.timeOut.message')}
              </Text>
              <Button onPress={onPress}>{t('refresh')}</Button>
            </View>
          )}
        </>
      )}
    </LoadingContainer>
  )
}

export default LoadingScreen
