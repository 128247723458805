export function sortByUpdatedTimestamp(
  a: { updatedTimestamp?: unknown },
  b: { updatedTimestamp?: unknown },
) {
  if (!a.updatedTimestamp && !b.updatedTimestamp) return 0
  if (!a.updatedTimestamp) return -1
  if (!b.updatedTimestamp) return 1

  return (
    (new Date(b.updatedTimestamp as any) as any) -
    (new Date(a.updatedTimestamp as any) as any)
  )
}
