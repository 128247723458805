import type { AxiosError } from 'axios'

import type { UpdateApplicationField } from '@/utils/api/application'

import { ApplicationStatus } from './ApplicationStatus'

const nodeEnv = process.env.NODE_ENV

export { ApplicationStatus }

export { appVersion } from './version'

export const EMPTY_ARRAY = Object.freeze([])

export const API_URL = process.env.EXPO_PUBLIC_API_URL as string
export const POSTHOG_API_KEY = process.env.EXPO_PUBLIC_POSTHOG_API as string

// FIXME: use env variable
export const DEFAULT_USER_PASSWORD = 'U_JzVuS0fxllVG"F'

export const MIXPANEL_TOKEN = 'b31f34795b72c9e56f7b99229c8d47d4'

export const PUSHER_APPLICATION_ID = 'c2e35eaff12c96f20ae1'
export const PUSHER_CLUSTER = 'us3'

export const SCREEN_MAX_WIDTH = 700

// https://www.notion.so/luvame/View-Static-Credit-Score-Card-c072e14b392245d4b900d3b1abe83031
export const MIN_CREDIT_SCORE = 300
export const MAX_CREDIT_SCORE = 850
export const CREDIT_SCORE_DELTA = MAX_CREDIT_SCORE - MIN_CREDIT_SCORE

export const buildVersion = process.env.EXPO_PUBLIC_BUILD_NUMBER

export const REFETCH_INTERVAL_MS = 7500
export const FAST_REFETCH_INTERVAL_MS = 5000
export const SLOW_REFETCH_INTERVAL_MS = 15000

export const VIN_LENGTH = 17
export const POSTAL_CODE_LENGTH = 5

export const LATEST_CAR_YEAR = 35
export const SSN_LENGTH = 9

export enum InclusionStatus {
  Add = 'ADD',
  Exclude = 'EXCLUDE',
}

export enum RelationshipStatus {
  Self = 'SELF',
  Spouse = 'SPOUSE',
  Child = 'CHILD',
  Parent = 'PARENT',
  Grandparent = 'GRANDPARENT',
  Grandchild = 'GRANDCHILD',
  Sibling = 'SIBLING',
  Other = 'OTHER',
  OtherRelative = 'OTHER_RELATIVE',
}

export const VALID_LOCALE_REGEX = /[a-z]{2}-[A-Z]{2}$/
export const VALID_ZIP_CODE_REGEX = /^\d{5}$/
export const VALID_CONFIRMATION_CODE = /^\d{6}$/
export const VALID_PHONE_NUMBER =
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
export const US_PHONE_PREFIX = '+1'
export const DEFAULT_LOCALE = 'en-US'

export enum RateCall {
  Rc1 = 'RATE_CALL1',
  Rc2 = 'RATE_CALL1_5',
  Rc3 = 'RATE_CALL_FINAL',
}

export const DATE_FORMAT = {
  en: 'MM/dd/yyyy',
  enAPI: 'yyyy-MM-dd',
  enRegex: /^([0-1]\d|([12]\d\d\d))[/-][0-3]\d[/-]([1-2]\d{3}|\d{2})$/,
} as const

export enum StorageKeys {
  TempPassword = 'ocho@temp-password',
  ChatSessionId = 'ocho@chat-session-id',
  TempPhone = 'ocho@temp-phone',
  TempWaitingPull = 'ocho@temp-waiting-pull',
  TempOchoProviderOnboardingComplete = 'ocho@temp-ocho-provider-onboarding-complete',
  TempAnalyticsParams = 'ocho@temp-analytics-params',
  TempPublisherContinueModalClosed = 'ocho@temp-publisher-continue-modal-closed',
}

export const DRIVING_RECORD_OPTIONS = [
  'PERFECT',
  'GREAT',
  'GOOD',
  'NOT_GOOD',
] as const

export const INCIDENTS_OPTIONS = ['YES', 'NO'] as const

export const MARITAL_STATUS_OPTIONS = [
  'SINGLE',
  'MARRIED',
  'SEPARATED',
  'DOMESTIC_PARTNER',
  'DIVORCED',
  'WIDOWED',
] as const

export enum DriverIncidentType {
  Accident = 'ACCIDENT',
  Violation = 'VIOLATION',
}

export const DRIVING_PERIODS = ['YEARLY', 'MONTHLY', 'WEEKLY', 'DAILY']

export const VEHICLE_USE_OPTIONS = [
  'PERSONAL',
  'COMMUTE',
  'ARTISAN',
  // 'DELIVERY',
  // 'BUSINESS',
  // 'UNKNOWN',
] as const

export const RELATIONSHIP_OPTIONS = [
  // 'SELF',
  'SPOUSE',
  'CHILD',
  'PARENT',
  'GRANDPARENT',
  'GRANDCHILD',
  'SIBLING',
  'OTHER',
  // 'OTHER_RELATIVE',
] as const

export const DRIVER_LICENSE_STATUS = [
  'ACTIVE',
  'EXPIRED',
  'PENDING',
  'REVOKED',
  'SUSPENDED',
  'TEMPORARY',
  // 'UNKNOWN',
] as const

export const EDUCATION_LEVEL_OPTIONS = [
  'ATTENDING_HIGH_SCHOOL',
  'HIGH_SCHOOL_GRADUATE',
  'VOCATION_TECHNICAL_DEGREE',
  'SOME_COLLEGE_NOT_ATTENDING',
  'CURRENTLY_IN_COLLEGE',
  'ASSOCIATES_DEGREE',
  'BACHELORS_DEGREE',
  'MASTERS_DEGREE',
  'PHD_DOCTORATE_DEGREE',
  'LAW_DEGREE',
  'MEDICAL_DEGREE',
  'UNKNOWN',
] as const

export const AUTO_INCIDENT_SUBTYPES_OPTIONS_INJURY = ['INJURY', 'NO_INJURY']
export const AUTO_INCIDENT_SUBTYPES_OPTIONS_FAULT = ['ACCID', 'ACCIDF']

export enum PaymentOption {
  CashInStore = 'CASH_IN_STORE',
  BankAccount = 'BANK_ACCOUNT',
  DebitCard = 'DEBIT_CARD',
}

export const PAYMENT_OPTIONS = [
  // PaymentOption.BankAccount,
  PaymentOption.DebitCard,
  // PaymentOption.CASH_IN_STORE, // NOTE: will be added in the future
] as const

export const AUTO_INCIDENT_OPTIONS = [
  'TESTREF',
  'ALCPOS',
  'DRGPOS',
  'DUI',
  'OPNCTN',
  'PRKVLN',
  'THEFT',
  'CHLD',
  'UNLIC',
  'REVK',
  'LICVLN',
  'INSPCT',
  'LICPLT',
  'RGSTR',
  'TIRE',
  'BRAKE',
  'EQUIP',
  'LIGHT',
  'EVADE',
  'APPEAR',
  'OBEY',
  'STOP',
  'YIELD',
  'FINRES',
  'LANE',
  'IMPPASS',
  'VEHHOM',
  'EXPL',
  'HITRUN',
  'RECKL',
  'BOAT',
  'SEATBELT',
  'SPEEDMAJ',
  'TSIG',
  'TURN',
  'PHONE',
  'CAREL',
  'RACE',
  'WRONG',
  'FOLLOW',
  'FELONY',
  'COMMI',
  'COMMA',
  'OTHERMI',
  'OTHERMA',
]

export const OCCUPATION_OPTIONS = [
  'HOME',
  'RET',
  'UNEM',
  'STUD',
  'AGRI',
  'ART',
  'BANK',
  'SALE',
  'CONST',
  'EDUC',
  'ENGI',
  'FOOD',
  'GOV',
  'INFOT',
  'INSUR',
  'LAW',
  'MED',
  'SERV',
  'MANUF',
  'REP',
  'SPORT',
  'TRAVEL',
  'UNKNOWN',
]

export const COUNTRY_OPTIONS = [
  'BR',
  'CA',
  'DO',
  'GT',
  'HN',
  'MX',
  'NI',
  'PE',
  'PR',
  'SV',
  'OT',
]

export const POST_DRIVER_VEHICLE_DISCOVERY_STEPS = [
  ApplicationStatus.DriverIncidentDiscovery,
  ApplicationStatus.QuotesRateCall15Pending,
  ApplicationStatus.QuotesRateCall15,
  ApplicationStatus.IssuerQuestions,
  ApplicationStatus.ProofDocumentationPending,
  ApplicationStatus.ProofDocumentation,
  ApplicationStatus.QuotesFinalPending,
  ApplicationStatus.QuotesFinalAgentLocked,
  ApplicationStatus.QuotesFinalBounced,
  ApplicationStatus.QuotesFinal,
  ApplicationStatus.QuotesFinalAccepted,
  ApplicationStatus.LoanPremiumFinanceSignaturePending,
  ApplicationStatus.LoanPremiumFinanceSigned,
  ApplicationStatus.PolicyGenerationPending,
  ApplicationStatus.PolicyGenerationAgentLocked,
  ApplicationStatus.PolicyGenerated,
  ApplicationStatus.PolicySignaturePending,
  ApplicationStatus.PolicySigned,
  ApplicationStatus.PolicyBound,
  ApplicationStatus.PaymentAccountCreated,
  ApplicationStatus.LoanDisbursed,
  ApplicationStatus.QuotesUnprocessable,
] as const

export const FINAL_APPLICATION_STATUSES = [
  ApplicationStatus.Expired,
  ApplicationStatus.PolicyBound,
  ApplicationStatus.NotAvailable,
  ApplicationStatus.PolicySigned,
  ApplicationStatus.QuotesUnprocessable,
  ApplicationStatus.LoanPrequalifiedDeclined,
] as const

export const MIN_LICENSE_NUMBER_LENGTH = 5
export const MAX_LICENSE_NUMBER_LENGTH = 15

export const ALTERNATE_IDENTIFICATIONS_OPTIONS = ['MATRICULA_CONSULAR']

export const GENDER_OPTIONS = ['FEMALE', 'MALE', 'UNSPECIFIED']

export const MIN_AGE_PRIMARY_DRIVER = 18
export const MIN_AGE_ADDITIONAL_DRIVER = 14
export const MAX_AGE = 100
export const BIRTHDATE_LENGTH = 10

export enum CarOwnership {
  Owned = 'OWNED',
  Leased = 'LEASED',
  Financed = 'FINANCED',
}

export const DEDUCTIBLE_OPTIONS = ['500', '1000']

export enum CarOwnershipLength {
  DoNotOwn = 'DO_NOT_OWN',
  LessThanMonth = 'LESS_THAN_MONTH',
  LessThan6Months = 'LESS_THAN_6_MONTHS',
  LessThan12Months = 'LESS_THAN_12_MONTHS',
  MoreThan12Months = 'MORE_THAN_12_MONTHS',
  MoreThan60Months = 'MORE_THAN_60_MONTHS',
  Unknown = 'UNKNOWN',
}

export const CAR_OWNERSHIP_OPTIONS = [
  CarOwnership.Owned,
  CarOwnership.Financed,
  CarOwnership.Leased,
]

export const CAR_OWNERSHIP_LENGTH_OPTIONS = [
  CarOwnershipLength.DoNotOwn,
  CarOwnershipLength.LessThan6Months,
  CarOwnershipLength.LessThan12Months,
  CarOwnershipLength.MoreThan12Months,
  CarOwnershipLength.MoreThan60Months,
]

export const isDevelopment =
  (typeof process !== 'undefined' && nodeEnv === 'development') ||
  (typeof __DEV__ !== 'undefined' && __DEV__)
/** Preview in Vercel or EAS */
export const isPreview =
  typeof process !== 'undefined' &&
  process.env.EXPO_PUBLIC_ENVIRONMENT === 'preview'
export const isProduction =
  typeof process !== 'undefined' ? nodeEnv === 'production' : true

export enum ConsentStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
export enum HttpStatus {
  Ok = 200,
  Created = 201,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  TooManyRequests = 429,
  ServerError = 500,
}
/* eslint-enable @typescript-eslint/no-magic-numbers */

export type UpdateApplicationError = AxiosError<{
  status?: number
  error?: string
  errorMessage?: string
  errorCode?: string
  fieldErrors?: {
    field: string
    code: string
    message: string
    rejectValue: string
  }[]
}>

export type UpdateApplicationVariables = {
  status: ApplicationStatus
  values: UpdateApplicationField[]
  updateQueryData?: boolean
  forceUpdate?: boolean
}

export enum PremiumFinanceContractType {
  NonFinanced = 'NON_FINANCED',
  Standard = 'STANDARD',
  Full = 'FULL',
}

export enum PaymentFrequency {
  Weekly = 'WEEKLY',
  BiWeekly = 'BI_WEEKLY',
  SemiMonthly = 'SEMI_MONTHLY',
  Monthly = 'MONTHLY',
}

export enum PreferredPaymentDay {
  PaycheckDay = 'PAYCHECK_DAY',
  PaycheckNextDay = 'PAYCHECK_NEXT_DAY',
}
