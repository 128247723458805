import { useMemo } from 'react'

import type { DefaultTheme } from 'styled-components/native'

import { Colors } from '@/utils/constants/theme'
import { CornerRadius, Space } from '@/utils/constants/theme'

import useColorScheme from './useColorScheme'

/**
 * For now this will always return the light theme
 * @returns
 * @default light
 */
export default function useTheme() {
  const colorScheme = useColorScheme()

  const theme = useMemo(
    (): DefaultTheme => ({
      colors: Colors[colorScheme] ?? Colors.light,
      cornerRadius: CornerRadius,
      scheme: colorScheme,
      space: Space,
    }),
    [colorScheme],
  )

  return { colorScheme, theme }
}
