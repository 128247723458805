import { useWindowDimensions } from 'react-native'

import Colors from '@/utils/constants/colors'
import useColorScheme from '@/utils/hooks/useColorScheme'

export { default as Colors } from '../colors'

export const FontSize = {
  Small: {
    n: 14,
    px: '14px',
  },
  Medium: {
    n: 16,
    px: '16px',
  },
  Large: {
    n: 18,
    px: '18px',
  },
  Xlarge: {
    n: 20,
    px: '20px',
  },
  Xxlarge: {
    n: 24,
    px: '24px',
  },
  Xxxlarge: {
    n: 36,
    px: '36px',
  },
} as const

export const Space = {
  Xxsmall: { px: '2px', n: 2 },
  Xsmall: { px: '4px', n: 4 },
  Small: { px: '8px', n: 8 },
  Medium: { px: '16px', n: 16 },
  Large: { px: '24px', n: 24 },
  Xlarge: { px: '32px', n: 32 },
  Xxlarge: { px: '48px', n: 48 },
} as const

export const CornerRadius = {
  Small: { px: '5px', n: 5 },
  Medium: { px: '10px', n: 10 },
  Large: { px: '16px', n: 16 },
  Xlarge: { px: '30px', n: 30 },
  Full: { px: '99999px', n: 99999 },
} as const

export const minimumTouchSize = 38

export const menuButtonIcon = 22

export const maxWidth = {
  smallPhone: 600,
  phone: 767,
  tablet: 1023,
  laptop: 1279,
  desktop: 1440,
}

export function useIsMobile() {
  return useWindowDimensions().width <= maxWidth.phone
}

export function useColors() {
  const scheme = useColorScheme()
  return Colors[scheme]
}
