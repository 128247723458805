import type { ComponentProps } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'

import { useNavigation } from '@react-navigation/native'

import Text from '@/components/Text'

import Help from '@/assets/icons/Help'
import { Colors } from '@/utils/constants/theme'
import { FontSize } from '@/utils/constants/theme'
import { useChatBadgeStore } from '@/utils/zustand'

const styles = StyleSheet.create({
  messageBadge: {
    backgroundColor: Colors.light.error,
    borderRadius: 8,
    height: 16,
    overflow: 'hidden',
    paddingVertical: 0,
    position: 'absolute',
    right: -5,
    top: -5,
    width: 16,
  },
  messageBadgeText: {
    color: 'white',
    fontSize: FontSize.Small.n,
    fontWeight: '900',
    textAlign: 'center',
  },
  pressable: {
    flexShrink: 0,
    justifyContent: 'center',
    minHeight: 31,
    minWidth: 31,
    position: 'relative',
  },
})

function ChatIcon(props: ComponentProps<typeof Pressable>) {
  const navigation = useNavigation()
  const chatBadge = useChatBadgeStore(({ count }) => count)

  return (
    <Pressable
      onPress={() => void navigation.navigate('ChatScreen')}
      style={styles.pressable}
      {...props}
    >
      <Help />
      {chatBadge > 0 && (
        <View style={styles.messageBadge}>
          <Text style={styles.messageBadgeText}>•</Text>
        </View>
      )}
    </Pressable>
  )
}

export default ChatIcon
