import type { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import * as analytics from '@/utils/analytics'
import type { Events } from '@/utils/analytics/events'
import { axiosInstance, getIdemPotencyKeyHeader } from '@/utils/api'
import { applicationKeys } from '@/utils/api/application'
import type {
  APIResponse,
  InsuranceAutoApplicationQuote,
  InsuranceAutoQuoteRateCall,
} from '@/utils/api/types'
import { getResponseData } from '@/utils/api/utils'
import { getIsAuthenticated } from '@/utils/auth'
import type { PremiumFinanceContractType } from '@/utils/constants'
import { RateCall } from '@/utils/constants'
import { MissingApplicationId } from '@/utils/errors'
import Sentry from '@/utils/sentry'
import { getCurrentApplicationId } from '@/utils/zustand'

export enum QuoteStatus {
  Error = 'ERROR',
  Queued = 'QUEUED',
  Failed = 'FAILED',
  Success = 'SUCCESS',
  Pending = 'PENDING',
  Cancelled = 'CANCELLED',
}

export type Quote = {
  id?: string
  normalizedQuote?: {
    premiumFinanceContractType?: PremiumFinanceContractType
    loan: Partial<InsuranceAutoQuoteRateCall['loan']>
  } & Omit<InsuranceAutoQuoteRateCall, 'premiumFinanceContractType' | 'loan'>
} & Omit<InsuranceAutoApplicationQuote, 'normalizedQuote' | 'id'>

export type QuotesData = {
  type: RateCall
  quotes: Quote[]
  coverageType?: string
  applicationId: string
  withCompAndCollision: boolean | string
  finalQuoteUpfrontPaymentAmount?: number
  updatedTimestamp: string
  createdTimestamp: string
  createdBy: string
  correlationId: string
  version: number
}

export type GetQuotesOptions = {
  rateCall: RateCall
  coverageType?: string
  applicationId: string
  limit?: number
  marketPlace?: boolean
  upfrontPaymentAmount?: number
  withCompAndCollision?: boolean
  nonSelectedAlternative?: boolean
}

export const NoQuotesData = 'No quotes data' as const

export async function getQuotes({
  rateCall,
  limit,
  coverageType,
  applicationId,
  marketPlace,
  upfrontPaymentAmount,
  withCompAndCollision,
  nonSelectedAlternative,
}: GetQuotesOptions) {
  const hasAuth = await getIsAuthenticated()

  if (!hasAuth) {
    throw new Error('Missing auth')
  }
  if (!applicationId) {
    throw new MissingApplicationId()
  }

  let path
  switch (rateCall) {
    case RateCall.Rc1:
      path = `${
        hasAuth ? '' : '/public'
      }/autoFlow/applications/${applicationId}/quotes`
      break
    case RateCall.Rc2:
    case RateCall.Rc3:
      path = `/autoFlow/applications/${applicationId}/quotes`
  }

  const { data } = await axiosInstance.get<APIResponse<QuotesData>>(path, {
    params: {
      allQuotes: rateCall === RateCall.Rc3 ? true : undefined,
      coverageType,
      marketPlace,
      nonSelectedAlternative,
      rateCallType: rateCall,
      upfrontPaymentAmount,
      withCompAndCollision,
    },
  })

  const quotesData = data?.data ?? ({} as QuotesData)
  // Here by throwing errors we will call the retry mechanism
  // so we are good after we have actual data
  if (!Object.keys(quotesData).length) {
    Sentry.addBreadcrumb({ message: 'No Quotes Data' })
    throw new Error(NoQuotesData)
  }

  if (limit) {
    quotesData.quotes = quotesData.quotes.slice(0, limit)
  }

  return quotesData
}

export function useQuotes(
  {
    limit,
    coverageType,
    withCompAndCollision,
    upfrontPaymentAmount,
    marketPlace,
    nonSelectedAlternative,
    rateCall = RateCall.Rc1,
  }: Omit<GetQuotesOptions, 'applicationId'>,
  options?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getQuotes>>, AxiosError<unknown>>
  >,
) {
  return useQuery({
    queryFn: () =>
      getQuotes({
        applicationId: getCurrentApplicationId() as string,
        coverageType,
        limit,
        marketPlace,
        nonSelectedAlternative,
        rateCall,
        upfrontPaymentAmount,
        withCompAndCollision,
      }),
    queryKey: applicationKeys.quotes({
      coverageType,
      limit,
      marketPlace,
      nonSelectedAlternative,
      rateCall,
      upfrontPaymentAmount,
      withCompAndCollision,
    }),
    staleTime: 0,
    ...options,
  })
}

type TriggerQuotesOptions = {
  withDelay?: boolean
  coverageType?: string
  quotesStage: RateCall
  applicationId: string
  withCompAndCollision?: boolean
}

export async function triggerQuotes(options: TriggerQuotesOptions) {
  const {
    withDelay,
    quotesStage,
    coverageType,
    applicationId,
    withCompAndCollision,
  } = options

  const hasAuth = await getIsAuthenticated()

  let path
  switch (quotesStage) {
    case RateCall.Rc1:
      path = `${
        hasAuth ? '' : '/public'
      }/autoFlow/applications/${applicationId}/initialQuotes`
      break
    case RateCall.Rc2:
      path = `/autoFlow/applications/${applicationId}/secondQuotes`
      break
    case RateCall.Rc3:
      path = `/autoFlow/applications/${applicationId}/finalQuotes`
      break
  }

  if (withDelay) {
    // We're adding a fake delay to account the time it takes to get
    // to link the user and update the application stage
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    await new Promise((resolve) => setTimeout(resolve, 3000))
  }

  return axiosInstance
    .patch(`${path}`, null, {
      headers: getIdemPotencyKeyHeader(),
      params: { coverageType, withCompAndCollision },
    })
    .then((response) => {
      const data = getResponseData(response)

      if (quotesStage === RateCall.Rc1) {
        /**
         * Legacy event, don't remove until Facebook is updated with
         * get__rate_call1
         */
        analytics.track({ id: 'rate_call1' as any })
      }

      analytics.track({ id: `get__${quotesStage.toLowerCase()}` as Events })

      return data
    })
}

export function useTriggerQuotes(
  {
    coverageType,
    withCompAndCollision,
  }: { withCompAndCollision?: boolean; coverageType?: string } = {},
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof triggerQuotes>>,
    AxiosError,
    { rateCall: RateCall; withDelay?: boolean }
  >,
) {
  return useMutation({
    mutationFn: ({ rateCall: currentRateCall, withDelay }) =>
      triggerQuotes({
        applicationId: getCurrentApplicationId() as string,
        coverageType,
        quotesStage: currentRateCall,
        withCompAndCollision,
        withDelay,
      }),
    mutationKey: ['trigger-quotes', { coverageType, withCompAndCollision }],
    ...options,
  })
}
