import type { Application } from '@/.d.ts'

import { axiosInstance, getIdemPotencyKeyHeader } from '@/utils/api'
import type { APIResponse } from '@/utils/api/types'
import { MissingApplicationId } from '@/utils/errors'

import { getResponseData } from '../utils'

export function completeFinalQuotesRequirements(applicationId: string) {
  if (!applicationId) throw new MissingApplicationId()

  return axiosInstance.patch<APIResponse<unknown>>(
    `/autoFlow/applications/${applicationId}/finalQuotes/requirements/complete`,
    undefined,
    { headers: getIdemPotencyKeyHeader(), params: { sendNotification: false } },
  )
}

export function updateProofDocumentation(
  applicationId: string,
  data: Record<string, string>[],
) {
  if (!applicationId) throw new MissingApplicationId()

  return axiosInstance
    .patch<
      APIResponse<Application>
    >(`/autoFlow/applications/${applicationId}/proofDocumentation`, data, { headers: getIdemPotencyKeyHeader() })
    .then(getResponseData)
}
