import { LinearGradient as ExpoLinearGradient } from 'expo-linear-gradient'
import transparentize from 'polished/lib/color/transparentize'
import styled from 'styled-components/native'

import Text from '@/components/Text'

import { SCREEN_MAX_WIDTH } from '@/utils/constants'
import { CornerRadius, FontSize, Space } from '@/utils/constants/theme'

const READABLE_BACKGROUND = '#705DEF'

export const Container = styled.View<{ $backgroundColor?: string }>`
  flex: 1;
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ?? theme.colors.backgroundContent};
`

export const LinearGradient = styled<any>(ExpoLinearGradient).attrs<{
  $color?: string
}>(({ theme, $color }) => ({
  colors: [
    transparentize(1, $color ?? theme.colors.backgroundContent),
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    transparentize(0.2, $color ?? theme.colors.backgroundContent),
    transparentize(0, $color ?? theme.colors.backgroundContent),
  ],
}))`
  width: 100%;
  height: 100%;
  align-items: center;
`

export const ToolbarKeyboardAVContainer = styled.KeyboardAvoidingView<{
  $hidden?: boolean
  $disabled: boolean
}>`
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
`

export const QuestionCaret = styled.View`
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  left: -9px;

  background-color: transparent;
  border-style: solid;

  border-top-width: 20px;
  border-top-color: transparent;
  border-right-width: 10px;
  border-right-color: ${READABLE_BACKGROUND};
`

export const LoadingContainer = styled.View`
  flex: 1;
  padding: ${Space.Medium.px};
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.backgroundContent};
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100px;
  gap: ${Space.Medium.px};
`

export const SlideContainer = styled.View<{ $height: number }>`
  height: ${({ $height }) => `${$height}px`};
  width: 100%;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.backgroundContent};
`

export const DialogTextContainer = styled.View<{
  rounded?: boolean | number
  $isSign?: boolean
}>`
  position: relative;
  padding: ${({ rounded }) => (rounded ? Space.Medium.px : Space.Medium.px)};
  background-color: ${() => READABLE_BACKGROUND};
  border-radius: ${({ $isSign, rounded }) =>
    typeof rounded === 'number'
      ? `${rounded}px`
      : $isSign
        ? CornerRadius.Large.px
        : CornerRadius.Medium.px};
  border-bottom-left-radius: ${({ rounded, $isSign }) =>
    typeof rounded === 'number'
      ? `${rounded}px`
      : $isSign
        ? CornerRadius.Large.px
        : rounded
          ? CornerRadius.Medium.px
          : 0};
  margin: ${Space.Xsmall.px} 0;
  font-size: ${FontSize.Large.px};
  max-width: 300px;
  z-index: 2;
`

export const SectionTitleText = styled(Text)`
  flex-shrink: 0;
  text-align: center;
`

export const QuestionsContainer = styled.View`
  padding: ${Space.Small.px};
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  display: flex;
  height: 100%;
  position: absolute;
  flex-direction: column;
  flex-grow: 0;
`

export const InputsContainer = styled.View`
  flex: 1;
  padding: 10px;
  max-width: ${SCREEN_MAX_WIDTH}px;
  margin: 0 auto;
  width: 100%;
`
