import { useEffect } from 'react'
import { Keyboard } from 'react-native'

import { ErrorBoundary } from '@sentry/react-native'
import { useShallow } from 'zustand/react/shallow'

import Modal from '@/modules/Modal'

import Alert from '@/components/Alert'

import { useAlertStore } from '@/utils/zustand'

function AlertModal() {
  const alert = useAlertStore(useShallow((state) => state.current))

  useEffect(() => {
    if (!alert) return

    Keyboard.dismiss()
  }, [alert])

  if (!alert) {
    return null
  }

  return (
    <Modal
      onDismiss={alert.onClose}
      heading={{ title: alert.title }}
      variant={alert.variant}
      isAlert
    >
      <ErrorBoundary fallback={null}>
        <Alert key={alert.title + alert.body} {...alert} />
      </ErrorBoundary>
    </Modal>
  )
}

export default AlertModal
