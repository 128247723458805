import { Pressable, StyleSheet, View } from 'react-native'

import Feather from '@expo/vector-icons/Feather'
import { useTranslation } from 'react-i18next'

import Text, { H5 } from '@/components/Text'
import { FontWeights } from '@/components/Text/utils'

import {
  CornerRadius,
  FontSize,
  Space,
  useColors,
} from '@/utils/constants/theme'

const styles = StyleSheet.create({
  cancelText: {
    color: '#333',
    flexShrink: 0,
    fontSize: FontSize.Medium.n,
    fontWeight: FontWeights.SemiBold,
  },
  container: { position: 'relative', width: '100%' },
  headerContainer: {
    alignItems: 'stretch',
    borderColor: '#eee',
    flexDirection: 'row',
    gap: Space.Medium.n,
    justifyContent: 'space-between',
    padding: Space.Medium.n,
    paddingHorizontal: Space.Medium.n,
    width: '100%',
  },
  item: {
    alignItems: 'flex-start',
    flexShrink: 0,
    justifyContent: 'center',
  },

  titleContainer: {
    alignItems: 'flex-start',
    flexBasis: 'auto',
    flexShrink: 1,
    justifyContent: 'center',
  },
})

function CloseIcon({
  onPress,
  variant,
}: {
  onPress?: () => void
  variant?: 'morado'
}) {
  const ICON_SIZE = 22
  const ICON_PADDING = 14

  const Colors = useColors()

  return (
    <Pressable onPress={onPress} style={styles.item}>
      <View
        style={{
          backgroundColor:
            variant === 'morado' ? Colors.primary : Colors.border,
          borderRadius: CornerRadius.Full.n,
          height: ICON_SIZE + ICON_PADDING,
          padding: 7,
          width: ICON_SIZE + ICON_PADDING,
        }}
      >
        <Feather
          color={variant === 'morado' ? Colors.background : Colors.text}
          name="x"
          size={ICON_SIZE}
        />
      </View>
    </Pressable>
  )
}

function SwipableIndicator() {
  const Colors = useColors()

  return (
    <View
      style={{
        backgroundColor: Colors.border,
        borderRadius: 9999,
        height: 4,
        left: '50%',
        position: 'absolute',
        top: Space.Small.n,
        transform: [{ translateX: -25 }],
        width: 50,
      }}
      aria-hidden
    />
  )
}

type ModalHeadingProps = {
  title?: string | React.ReactNode
  leftItem?: string | React.ReactNode
  rightItem?: string | React.ReactNode
  onRightItemPress?: () => void
  onLeftItemPress?: () => void
  swipeable?: boolean
  showCloseButton?: boolean
  /** @deprecated */
  variant?: 'morado'
}

export default function ModalHeading({
  title,
  onLeftItemPress,
  onRightItemPress,
  rightItem = 'cancel',
  leftItem = null,
  swipeable,
  showCloseButton = true,
  variant,
  ...props
}: ModalHeadingProps) {
  const { t } = useTranslation()

  return (
    <View style={styles.container} {...props}>
      {Boolean(swipeable) && <SwipableIndicator />}
      <View style={styles.headerContainer}>
        {leftItem ? (
          <Pressable onPress={onLeftItemPress} style={styles.item}>
            {typeof leftItem === 'object' ? (
              leftItem
            ) : (
              <Text style={styles.cancelText}>{t(leftItem as string)}</Text>
            )}
          </Pressable>
        ) : null}

        {title && typeof title === 'string' ? (
          <View aria-level="1" role="heading" style={styles.titleContainer}>
            <H5 color={variant === 'morado' ? 'white' : undefined}>{title}</H5>
          </View>
        ) : title ? (
          title
        ) : (
          <View style={{ flex: 1 }} aria-hidden />
        )}

        {showCloseButton === true &&
          (typeof rightItem === 'object' ? (
            rightItem
          ) : (
            <CloseIcon onPress={onRightItemPress} variant={variant} />
          ))}
      </View>
    </View>
  )
}
