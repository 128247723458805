import { StyleSheet, View } from 'react-native'

import type { StepIds } from '@/screens/NewApplication/ids'

import HeaderMenu from '@/modules/HeaderMenu/index'
import type { NextPressParams } from '@/modules/StepToolbar/utils'

import ChatIcon from '@/components/ChatIcon'

import { Space } from '@/utils/constants/theme'

export default function HeaderRight({
  showChat,
  showMenu,
  moveToStep,
  showDriversAndVehicleReset,
}: {
  showChat: boolean
  showMenu?: boolean
  moveToStep?: NextPressParams<StepIds>['moveToStep']
  showDriversAndVehicleReset?: boolean
}) {
  return (
    <View style={styles.headerRight}>
      {showChat ? <ChatIcon /> : null}
      {showMenu ? (
        <HeaderMenu
          moveToStep={moveToStep}
          showDriversAndVehicleReset={showDriversAndVehicleReset}
        />
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  headerRight: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: Space.Medium.n,
    marginRight: Space.Medium.n,
  },
})
