import darken from 'polished/lib/color/darken'
import desaturate from 'polished/lib/color/desaturate'
import lighten from 'polished/lib/color/lighten'
import readableColor from 'polished/lib/color/readableColor'
import transparentize from 'polished/lib/color/transparentize'
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Platform } from 'react-native'

import type { DefaultTheme } from 'styled-components/native'
import styled, { css } from 'styled-components/native'

import StyledText from '@/components/Text'

import { Colors, Space } from '@/utils/constants/theme'

const DESATURATE = 0.9
const TRANSPARENTIZE = 0.25

type CommonProps = {
  $type?: string
  $disabled?: boolean
  $transparent?: boolean
  $color?: string
}

type ButtonTextProps = {
  $textColor?: string
} & CommonProps

type ContentProps = { $padding?: string; $small?: boolean } & CommonProps

type ButtonContainerProps = {
  $isBottomButton?: boolean
  $isPlaceholder?: boolean
} & CommonProps

function getBackgroundColor({
  $transparent,
  $color,
  theme,
  $type,
  $disabled,
}: { theme: DefaultTheme } & ContentProps) {
  if ($transparent) return 'transparent'

  let color: string = theme.colors.primary
  if ($color) color = $color
  if ($type === 'secondary') return 'transparent'
  if ($type === 'destructive') color = theme.colors.error
  if ($disabled || $type === 'disabled') {
    color = transparentize(
      TRANSPARENTIZE,
      lighten(0.4, desaturate(DESATURATE, color)),
    )
    if (theme.scheme === 'dark') {
      color = transparentize(
        TRANSPARENTIZE,
        darken(0.7, desaturate(DESATURATE, color)),
      )
    }
  }
  return color
}

function getForegroundColor(props: { theme: DefaultTheme } & ButtonTextProps) {
  if (props.$disabled) return Colors.light.textSecondary
  if (props.$textColor) return props.$textColor
  if (props.$transparent) return props.$color ?? props.theme.colors.primary
  if (props.$color) {
    const foregroundColor = darken(0.1125, props.$color)
    if (props.$type === 'secondary') return foregroundColor
    const backgroundColor = getBackgroundColor(props)
    return readableColor(
      backgroundColor,
      darken(0.5, props.$color),
      lighten(0.5, props.$color),
    )
  }
  if (props.$type === 'secondary')
    return darken(0.1125, props.theme.colors.primary)
  return 'white'
}

function getBorderColor(props: { theme: DefaultTheme } & ButtonTextProps) {
  if (props.$transparent) return undefined
  if (props.$type === 'secondary') {
    if (props.$disabled) return Colors.light.textSecondary
    return props.$color ?? props.theme.colors.primary
  }

  return undefined
}

export const Content = styled.View<ContentProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Space.Xsmall.px};
  justify-content: center;
  height: ${Platform.OS === 'web' ? '100%' : 'auto'};
  padding: ${({ $padding, $small }) =>
    $padding ?? ($small ? `2px 0` : `10px ${Space.Medium.px}`)};
  background-color: ${getBackgroundColor};
  min-height: 20px;
`

export const Text = styled(StyledText).attrs<ButtonTextProps>(
  ({
    align = 'center',
    size = 'medium',
    weight = 'medium',
    ...props
  }: any) => ({
    ...props,
    align,
    size,
    weight,
  }),
)`
  color: ${getForegroundColor};
  opacity: 1;
  text-align: center;
`

export const Container = styled.Pressable<ButtonContainerProps>`
  overflow: hidden;
  border: ${(props) => {
    const borderColor = getBorderColor(props)

    return borderColor ? `2px solid ${borderColor}` : '0px'
  }};
  border-radius: ${({ $type, $transparent }) =>
    // IMPORTANT: It's important to always send a px value to the border-radius property
    $transparent || $type === 'transparent' ? '0px' : '99999999px'};

  ${({ $isPlaceholder, theme }) =>
    $isPlaceholder &&
    css`
      border: 2px dashed ${theme.colors.placeholder};
      padding: ${Space.Xsmall.px};
    `}
  ${({ $isBottomButton }) =>
    $isBottomButton &&
    css`
      margin: ${Space.Medium.px};
      margin-left: auto;
      margin-right: auto;
      max-width: 300px;
      width: 100%;
    `}
`
