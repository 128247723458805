export const MORADO = '#7664EF'
export const VERDE = '#11CD67'
export const MAGENTA = '#C2417D'

export const DURAZNO = '#F6BFAF'
export const LAVANDA = '#C6C6EE'
export const LAVANDA_BG = '#E3E3F3'
export const LAVANDA_ALT = '#DED9FF'
export const LIMA = '#21EC81'
export const MENTA = '#B3EFCE'
export const SANDIA = '#E5454B'
export const MELON = '#FF9933'
export const NARANJA = '#FF7110'
export const NUBE = '#F1F1F8'
export const NEBLINA = '#E6E6ED'
export const BLANCO = '#FFFFFF'
export const ROSADO = '#FFCED9'

export const NEGRO = '#000'
export const GRIS_0 = '#111'
export const GRIS_1 = '#5a5a61'
export const GRIS_2 = '#88888F'
export const GRIS_3 = '#CCCCD3'

//

export const MORADO_DARK = '#584AB0'
export const VERDE_DARK = '#0EB358'

/** Preserve the rgba syntax */
export const OVERLAY_COLOR = 'rgba(0, 0, 0, 0.8)' as const

const Colors = {
  dark: {
    background: '#111',
    backgroundContent: NEGRO,
    border: '#222',
    disabled: '#333',
    error: SANDIA,
    label: '#ddd',
    placeholder: '#999',
    primary: MORADO_DARK,
    secondary: VERDE_DARK,
    secondaryText: VERDE_DARK,
    tabIconDefault: '#ddd',
    tabIconSelected: MORADO_DARK,
    tertiary: MAGENTA,
    text: '#fff',
    textSecondary: '#ddd',
    warning: MELON,
  },
  light: {
    background: BLANCO,
    backgroundContent: NUBE,
    border: NUBE,
    disabled: NEBLINA,
    error: SANDIA,
    label: GRIS_0,
    placeholder: GRIS_2,
    primary: MORADO,
    secondary: VERDE,
    secondaryText: '#0d9e4f',
    tabIconDefault: GRIS_2,
    tabIconSelected: MORADO,
    tertiary: MAGENTA,
    text: NEGRO,
    textSecondary: GRIS_1,
    warning: MELON,
  },
} as const

export default Colors
