import { useCallback, useEffect, useState } from 'react'

import Feather from '@expo/vector-icons/Feather'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/native'
import * as DropdownMenu from 'zeego/dropdown-menu'

import type { StepIds } from '@/screens/NewApplication/ids'
import {
  useCoverageType,
  useWithCompAndCollision,
} from '@/screens/NewApplication/Steps/Quotes/utils'

import type { NextPressParams } from '@/modules/StepToolbar/utils'

import { H4 } from '@/components/Text'

import * as analytics from '@/utils/analytics'
import { Events } from '@/utils/analytics/events'
import signOut from '@/utils/auth/sign-out'
import { Colors, Space } from '@/utils/constants/theme'
import { SupportedLanguage } from '@/utils/language'
import { useAuthStore, useShowModal } from '@/utils/zustand'

const StyledContent = styled(DropdownMenu.Content)`
  background-color: ${Colors.light.primary};
  gap: ${Space.Small.px};
  padding: ${Space.Medium.px};
  padding-bottom: ${Space.Xlarge.px};
  padding-left: ${Space.Xlarge.px};
`
const StyledTitle = styled(H4)`
  color: ${Colors.light.background};
  cursor: pointer;
`

const DropdownMenuContent = DropdownMenu.create(StyledContent, 'Content')
const DropdownMenuItemTitle = DropdownMenu.create(StyledTitle, 'ItemTitle')

function HeaderMenu({
  moveToStep,
  showDriversAndVehicleReset,
}: {
  moveToStep?: NextPressParams<StepIds>['moveToStep']
  showDriversAndVehicleReset?: boolean
}) {
  const { i18n, t } = useTranslation()
  const { navigate } = useNavigation()
  const showModal = useShowModal()
  const setCurrentApplicationId = useAuthStore(
    ({ setCurrentApplicationId }) => setCurrentApplicationId,
  )

  const [isOpen, setIsOpen] = useState(false)

  const coverageType = useCoverageType()
  const withCompAndCollision = useWithCompAndCollision()

  useEffect(() => {
    if (isOpen) analytics.track({ id: Events.UIClickHamburgerMenuOpen })
  }, [isOpen])

  const handleChangeLanguage = useCallback(
    () =>
      void i18n.changeLanguage(
        i18n.language === SupportedLanguage.EsUs
          ? SupportedLanguage.EnUs
          : SupportedLanguage.EsUs,
      ),
    [i18n],
  )

  const handleShowDriverVehicleResetModal = useCallback(() => {
    if (moveToStep) {
      showModal('driver-vehicle-reset', {
        isFullSize: true,
        moveToStep,
        params: { coverageType, withCompAndCollision },
        showCloseButton: true,
        variant: 'morado',
      })
    }
  }, [coverageType, moveToStep, showModal, withCompAndCollision])

  const navigateToHome = useCallback(() => {
    setCurrentApplicationId(null)
    navigate('TabNavigator')
  }, [navigate, setCurrentApplicationId])

  const handleSignOut = useCallback(() => {
    void signOut({ amplifySignOut: true, redirect: true })
  }, [])

  return (
    <DropdownMenu.Root onOpenChange={setIsOpen}>
      <DropdownMenu.Trigger>
        <Feather
          color={Colors.light.background}
          name={isOpen ? 'x' : 'menu'}
          size={Space.Large.n}
        />
      </DropdownMenu.Trigger>
      <DropdownMenuContent
        // All these props are not supposed to be required according to docs, but they are in the types 🤷‍.
        align="left"
        alignOffset={0}
        collisionPadding={0}
        loop={false}
        side="bottom"
        sideOffset={0}
        avoidCollisions
      >
        <DropdownMenu.Item
          key="change-language"
          onSelect={handleChangeLanguage}
        >
          <DropdownMenuItemTitle>
            {t('headerMenu.changeLanguage')}
          </DropdownMenuItemTitle>
        </DropdownMenu.Item>
        <DropdownMenu.Item key="my-account" onSelect={navigateToHome}>
          <DropdownMenuItemTitle>
            {t('headerMenu.myAccount')}
          </DropdownMenuItemTitle>
        </DropdownMenu.Item>
        {showDriversAndVehicleReset && moveToStep ? (
          <DropdownMenu.Item
            key="drivers-and-cars"
            onSelect={handleShowDriverVehicleResetModal}
          >
            <DropdownMenuItemTitle>
              {t('headerMenu.driversAndCars')}
            </DropdownMenuItemTitle>
          </DropdownMenu.Item>
        ) : null}
        <DropdownMenu.Item key="sign-out" onSelect={handleSignOut}>
          <DropdownMenuItemTitle>
            {t('headerMenu.signOut')}
          </DropdownMenuItemTitle>
        </DropdownMenu.Item>
      </DropdownMenuContent>
    </DropdownMenu.Root>
  )
}

export default HeaderMenu
