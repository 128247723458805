import { ReactNode } from 'react'

import { QueryClientProvider } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'

import { isProduction } from '@/utils/constants'
import queryClient, { persistOptions } from '@/utils/queryClient/index'

function ProviderWithPersistence({ children }: { children: ReactNode }) {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={persistOptions}
    >
      {children}
    </PersistQueryClientProvider>
  )
}

function ProviderWithoutPersistence({ children }: { children: ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

export default isProduction
  ? ProviderWithoutPersistence
  : ProviderWithPersistence
