export class MissingArgumentError extends Error {
  static getName() {
    return 'MissingArgumentError'
  }

  constructor(message: string) {
    super(message)

    this.name = 'MissingArgumentError'
  }
}

export class MissingCustomerId extends MissingArgumentError {
  constructor() {
    super('Missing customerId')
  }
}

export class MissingOwnerId extends MissingArgumentError {
  constructor() {
    super('Missing ownerId')
  }
}

export class MissingApplicationId extends MissingArgumentError {
  constructor() {
    super('Missing applicationId')
  }
}

export class MissingProductId extends MissingArgumentError {
  constructor() {
    super('Missing productId')
  }
}

export class MissingOrderId extends MissingArgumentError {
  constructor() {
    super('Missing orderId')
  }
}

export class MissingSessionId extends MissingArgumentError {
  constructor() {
    super('Missing sessionId')
  }
}
