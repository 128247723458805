import { Platform } from 'react-native'

import type { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs'
import { createNavigationContainerRef } from '@react-navigation/native'
import type { NativeStackNavigationOptions } from '@react-navigation/native-stack'

import HeaderRight from '@/modules/HeaderMenu/HeaderRight'

import OchoHeaderTitle from '@/components/HeaderTitle'

import { Colors } from '@/utils/constants/theme'

export const navigationRef = createNavigationContainerRef()

const HEADER_STYLE_HEIGHT = 48

export function getHeaderStyle({
  color,
  colorScheme,
}: {
  color?: string
  colorScheme?: 'light' | 'dark'
}) {
  return {
    backgroundColor:
      color ?? (colorScheme ? Colors[colorScheme].primary : null),
    borderBottomWidth: 0,
    height: Platform.OS === 'web' ? HEADER_STYLE_HEIGHT : undefined,
  }
}

export const defaultHeaderOptions = Object.freeze(
  (
    { navigation }: { navigation?: any } = {},
    {
      presentation,
      colorScheme = 'light',
      showChat = true,
      showMenu = true,
    }: {
      presentation?: NativeStackNavigationOptions['presentation']
      showChat?: boolean
      showMenu?: boolean
      colorScheme?: 'light' | 'dark'
    } = {},
  ) =>
    ({
      animationEnabled: true,
      headerRight: () => (
        <HeaderRight
          showChat={Boolean(navigation && showChat)}
          showMenu={Boolean(navigation && showMenu)}
        />
      ),
      headerStyle: getHeaderStyle({ colorScheme }),
      headerTintColor: 'white',
      headerTitle: OchoHeaderTitle,
      headerTitleAlign: Platform.OS === 'web' ? 'center' : undefined,
      headerTitleStyle: {
        fontWeight: '700',
      },
      presentation,
    }) as BottomTabNavigationOptions & NativeStackNavigationOptions,
)

export function navigate(
  name: string,
  params?: Record<string, any> | undefined,
) {
  if (navigationRef.isReady()) {
    navigationRef.navigate<any>(name, params)
  }
}
