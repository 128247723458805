// https://github.com/TanStack/query/issues/293#issuecomment-1306136920

import { useRef } from 'react'

import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
} from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

/**
 * This is the way we can use the UseQueryOptions so that we maintain the useQuery API
 * and at the same time maintain the types for the return of the queryFn, otherwise the
 * data property would default to unknown.
 */
interface UseDebouncedQueryOptions<T, TError>
  extends UseQueryOptions<T, TError> {
  queryFn: QueryFunction<T>
}

export function useDebouncedQuery<T, TError>({
  queryFn,
  queryKey,
  debounce,
  ...remainingUseQueryOptions
}: { debounce: number } & UseDebouncedQueryOptions<T, TError>) {
  const timeoutRef = useRef<number>()
  const queryClient = useQueryClient()
  const previousQueryKeyRef = useRef<QueryKey>()

  return useQuery<T, TError>({
    ...remainingUseQueryOptions,

    queryFn: (queryFnContext) => {
      // This means the react-query is retrying the query, so we should not debounce it.
      if (previousQueryKeyRef.current === queryKey) {
        return queryFn(queryFnContext)
      }

      /**
       * We need to cancel previous "pending" queries otherwise react-query will give us an infinite
       * loading state for this key since the Promise we returned was neither resolved nor rejected.
       */
      if (previousQueryKeyRef.current) {
        queryClient.cancelQueries({ queryKey: previousQueryKeyRef.current })
      }

      previousQueryKeyRef.current = queryKey
      window.clearTimeout(timeoutRef.current)

      return new Promise((resolve, reject) => {
        timeoutRef.current = window.setTimeout(async () => {
          try {
            const result = await queryFn(queryFnContext)

            previousQueryKeyRef.current = undefined
            resolve(result as T)
          } catch (error) {
            reject(error)
          }
        }, debounce)
      })
    },
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,
  })
}
