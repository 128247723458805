import { createGlobalStyle } from 'styled-components'
import styled from 'styled-components/native'

import { CornerRadius, FontSize } from '@/utils/constants/theme'

export const LABEL_TOP_PADDING = 14.5

export const TextInput = styled.TextInput.attrs(({ theme }) => ({
  placeholderTextColor: theme.colors.placeholder,
}))<{
  $error?: boolean
  $warning?: boolean
  $hasLabel?: boolean
  disabled?: boolean
}>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.textSecondary : theme.colors.text};
  /* Custom border radius only for web, text  */
  border-radius: 8px;
  padding: ${({ $hasLabel }) => ($hasLabel ? '15px 10px 5px' : '10px')};
  font-size: ${FontSize.Large.px};
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  outline-color: ${({ theme }) => theme.colors.primary};
`

export default createGlobalStyle`
  select {
    border: none;
    font-size: ${FontSize.Large.px};
    appearance: none;
    background-color: transparent;
    color: black;
    min-height: 21px;
  }

  select[value=""] {
    color: gray;
  }


  #driver,
  #new-debit-card,
  #vehicle, 
  #generic-modal, 
  #address-lookup {
    margin-top: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      position: fixed;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-bottom: 100%;
      background-color: rgba(0,0,0,0.5);
      z-index: -3;
    }
  }


  @media (min-width: 600px) {
    /* #max-size-modal-content {
      max-width: 500px;
      margin: auto;
      width: 100%;
      border-radius: ${CornerRadius.Xlarge.px};
      max-height: 700px;
      height: 100%;
    } */

    #incident,
    #driver,
    #new-debit-card,
    #vehicle, 
    #generic-modal,
    #address-lookup {
      margin: auto;
      max-width: 800px;
      max-height: 800px;
      border-radius: ${CornerRadius.Medium.px};
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  /* TODO: re-enable when dark mode is supported */
  /* @media (prefers-color-scheme: dark) {
    select {
      color: white;
      option{
        color: white;
      }
    }
  } */
`
