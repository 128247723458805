import { Platform } from 'react-native'

import { FontDisplay, useFonts } from 'expo-font'

export const FontFamily = {
  Bold: 'Spezia-Bold',
  Medium: 'Spezia-Medium',
  Regular: 'Spezia-Regular',
  SemiBold: 'Spezia-SemiBold',
}

export default function useSpeziaFont() {
  return useFonts(
    Platform.OS === 'web'
      ? {
          [FontFamily.Bold]: {
            display: FontDisplay.SWAP,
            uri: require('@/assets/fonts/Spezia-Bold.otf'),
          },
          [FontFamily.Medium]: {
            display: FontDisplay.SWAP,
            uri: require('@/assets/fonts/Spezia-Medium.otf'),
          },
          [FontFamily.Regular]: {
            display: FontDisplay.SWAP,
            uri: require('@/assets/fonts/Spezia-Regular.otf'),
          },
          [FontFamily.SemiBold]: {
            display: FontDisplay.SWAP,
            uri: require('@/assets/fonts/Spezia-SemiBold.otf'),
          },
        }
      : {},
  )
}
