import { StyleSheet } from 'react-native'

import Colors from '@/utils/constants/colors'

const styles = StyleSheet.create({
  iframe: { backgroundColor: Colors.light.background, borderWidth: 0, flex: 1 },
})

function WebView({ route, source }: { route?: any; source?: string }) {
  let iframeSource = route?.params?.source ?? source

  if (route?.params.section) {
    iframeSource = `${iframeSource}#${route?.params.section}`
  }

  return <iframe src={iframeSource} style={styles.iframe} title="iframe" />
}

export default WebView
