import PostHog from 'posthog-js'
import {
  PostHogProvider,
  useActiveFeatureFlags,
  useFeatureFlagVariantKey,
} from 'posthog-js/react'

import { API_URL, POSTHOG_API_KEY } from '@/utils/constants'
import Sentry from '@/utils/sentry'

type FlagKey = `${string}--${string}--${string}`

let posthogClient: typeof PostHog | undefined

try {
  PostHog.init(POSTHOG_API_KEY, {
    api_host: `${API_URL}/api/v1/public/proxy/ingest`,
    ui_host: 'https://us.posthog.com',
  })

  identify()

  posthogClient = PostHog
} catch (error) {
  Sentry.captureException(error)
}

export function identify(
  userId?: string,
  properties?: Record<string, unknown>,
) {
  return posthogClient?.identify(userId, properties)
}

/**
 * This function only takes effect in native
 */
export function screen(_pageName: string, _metadata: any) {
  return Promise.resolve(undefined as void)
}

export function useFeatureFlags() {
  return useActiveFeatureFlags()
}

/**
 * @deprecated use useFeatureFlag from '@/utils/analytics/feature-flags'
 */
export function useFeatureFlag<T extends string>(flag: FlagKey) {
  return useFeatureFlagVariantKey(flag) as T | undefined | 'control'
}

export function Provider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider client={posthogClient}>{children}</PostHogProvider>
}

/**
 * Register super properties
 */
export function register(props: Record<string, string | number>) {
  return posthogClient?.register(props)
}

/**
 * Unregister super property
 */
export function unregister(prop: string) {
  return posthogClient?.unregister(prop)
}

export { posthogClient }
