import type { AxiosResponse } from 'axios'

import type { APIResponse } from './types'

/**
 * Utility function to extract data from an Ocho API AxiosResponse,
 * it will return null if no data is found so it's compatible with
 * React Query's hooks.
 * @param response AxiosResponse from Ocho API
 * @returns {T | null}
 */
export function getResponseData<T>(response: AxiosResponse<APIResponse<T>>) {
  return response?.data?.data ?? null
}
