import { useRef } from 'react'

export const IdempotencyKeyHeaderName = 'Idempotency-Key' as const

type IdempotencyKey = `${string}-${string}-4${string}-${string}-${string}`

export function getIdemPotencyKey() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const r = (Math.random() * 16) | 0
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const v = c == 'x' ? r : (r & 0x3) | 0x8
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return v.toString(16)
  }) as IdempotencyKey
}

export function useIdemPotencyKey(): string {
  const { current } = useRef(getIdemPotencyKey())
  return current
}

export function getIdemPotencyKeyHeader(key: string = getIdemPotencyKey()) {
  return { [IdempotencyKeyHeaderName]: key } as const
}
