/**
 * We should import these first to ensure that they are loaded before
 * anything else.
 */
// eslint-disable perfectionist/sort-imports
import '@/utils/polyfills'
import '@/utils/sentry'
// eslint-enable perfectionist/sort-imports

import { registerRootComponent } from 'expo'

import App from '@/Root'

registerRootComponent(App)
