/* eslint-disable max-lines */
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export default function Family() {
  return (
    <Svg fill="none" height="178" viewBox="0 0 269 178" width="269">
      <G clipPath="url(#clip0_79_830)">
        <Path
          d="M166.167 112.342L54.166 129.331C57.9743 114.188 70.5217 102.055 86.9024 99.5839L124.587 93.3055C148.517 93.3055 150.837 92.5872 159.375 100.105C162.38 102.759 164.062 108.865 166.163 112.342H166.167Z"
          fill="black"
        />
        <Path
          d="M125.227 85.1364L125.815 89.0442C127.278 98.7598 120.598 107.822 110.901 109.287C101.201 110.753 92.1537 104.061 90.6906 94.3492L90.1025 90.4414L125.231 85.1364H125.227Z"
          fill="#E9A362"
        />
        <G opacity="0.2">
          <Path
            d="M125.231 85.1364L125.779 88.782C120.888 92.2516 115.147 94.6545 108.843 95.6063C102.539 96.5581 96.3493 95.9583 90.6512 94.087L90.1025 90.4414L125.231 85.1364Z"
            fill="black"
          />
        </G>
        <Path
          d="M140.202 36.5765C136.927 14.8056 116.652 -0.183599 94.9166 3.09713C73.1817 6.37786 58.2174 26.6862 61.493 48.4571L63.0123 58.5544C66.2879 80.3253 86.5629 95.3145 108.298 92.0337C130.033 88.753 144.997 68.4447 141.721 46.6738L140.202 36.5765Z"
          fill="#E9A362"
        />
        <Path
          d="M81.8135 57.6696C84.4109 57.2952 86.2135 54.8826 85.8396 52.2811C85.4658 49.6795 83.0572 47.8741 80.4598 48.2485C77.8624 48.623 76.0599 51.0355 76.4337 53.637C76.8075 56.2386 79.2162 58.044 81.8135 57.6696Z"
          fill="#673400"
        />
        <Path
          d="M81.5113 55.7524C83.0521 55.5303 84.1214 54.0992 83.8996 52.5559C83.6779 51.0127 82.2491 49.9417 80.7083 50.1638C79.1675 50.3859 78.0982 51.817 78.32 53.3603C78.5417 54.9035 79.9706 55.9745 81.5113 55.7524Z"
          fill="black"
        />
        <Path
          d="M81.9868 52.3933C82.6087 52.3933 83.1128 51.8884 83.1128 51.2655C83.1128 50.6426 82.6087 50.1377 81.9868 50.1377C81.365 50.1377 80.8608 50.6426 80.8608 51.2655C80.8608 51.8884 81.365 52.3933 81.9868 52.3933Z"
          fill="#F1F1F8"
        />
        <Path
          d="M118.597 52.1159C121.189 51.707 122.959 49.2707 122.551 46.6744C122.143 44.078 119.711 42.3047 117.118 42.7136C114.526 43.1225 112.756 45.5588 113.164 48.1551C113.572 50.7515 116.005 52.5248 118.597 52.1159Z"
          fill="#673400"
        />
        <Path
          d="M118.318 50.1964C119.855 49.9538 120.905 48.5086 120.663 46.9684C120.421 45.4282 118.978 44.3763 117.441 44.6189C115.903 44.8614 114.853 46.3066 115.095 47.8468C115.337 49.387 116.78 50.4389 118.318 50.1964Z"
          fill="black"
        />
        <Path
          d="M116.553 47.1726C117.175 47.1726 117.679 46.6677 117.679 46.0448C117.679 45.4219 117.175 44.917 116.553 44.917C115.931 44.917 115.427 45.4219 115.427 46.0448C115.427 46.6677 115.931 47.1726 116.553 47.1726Z"
          fill="#F1F1F8"
        />
        <Path
          d="M99.4217 49.6994L99.4182 49.7C97.0838 50.0523 95.4766 52.2334 95.8284 54.5715L96.6128 59.7853C96.9646 62.1235 99.1422 63.7332 101.477 63.3809L101.48 63.3804C103.814 63.028 105.422 60.8469 105.07 58.5088L104.285 53.295C103.934 50.9569 101.756 49.3471 99.4217 49.6994Z"
          fill="#BA824E"
        />
        <Path
          d="M103.621 77.5603C91.2604 79.428 87.6206 75.1286 87.6206 75.1286L118.345 67.7548C118.345 67.7548 115.979 75.6926 103.621 77.5603Z"
          fill="white"
        />
        <Path
          d="M97.7914 78.5274C89.9597 78.5274 87.3742 75.6001 87.2451 75.4493L86.7251 74.8351L119.063 67.0733L118.82 67.8958C118.794 67.9821 118.167 70.0222 115.976 72.2993C113.975 74.3789 110.217 77.062 103.698 78.0461C101.46 78.3837 99.5019 78.5238 97.795 78.5238L97.7914 78.5274ZM88.6902 75.3846C89.2066 75.7402 90.0314 76.2107 91.247 76.613C93.4273 77.3421 97.3324 78.0102 103.547 77.0728C109.761 76.1353 113.326 73.6103 115.219 71.66C116.435 70.4065 117.141 69.2248 117.514 68.4633L88.6902 75.381V75.3846Z"
          fill="#BA824E"
        />
        <Path
          d="M61.2247 46.2381C61.2247 46.2381 71.4734 39.6113 73.5605 26.9684C75.6475 14.3255 81.8763 24.7523 97.8124 22.3458L94.6101 1.07912L90.0595 1.79028C82.1202 3.02943 74.7008 6.64991 68.9991 12.3213C62.1499 19.1348 56.484 30.0034 61.2247 46.2417V46.2381Z"
          fill="black"
        />
        <Path
          d="M139.809 34.3674C139.809 34.3674 128.065 31.063 122.349 19.5981C116.633 8.13329 113.75 19.9358 97.8136 22.3422L94.6113 1.07551L99.1691 0.41104C107.119 -0.749093 115.274 0.518793 122.392 4.25421C130.944 8.7403 139.558 17.4539 139.805 34.3674H139.809Z"
          fill="black"
        />
        <Path
          d="M72.1081 49.8025C71.8858 49.8025 71.6598 49.7522 71.4483 49.6444C70.7167 49.2781 70.4191 48.3873 70.7849 47.6546C73.6178 41.9761 78.1576 40.6543 81.4639 40.543C84.9423 40.4316 87.7429 41.6169 87.8613 41.6672C88.6107 41.9904 88.9586 42.8632 88.6359 43.6139C88.3131 44.3646 87.4453 44.713 86.6958 44.3933C86.3157 44.2317 77.5875 40.6507 73.4313 48.9836C73.1731 49.5044 72.6496 49.8025 72.1045 49.8025H72.1081Z"
          fill="black"
        />
        <Path
          d="M125.12 41.7977C124.729 41.7977 124.342 41.6432 124.051 41.3379C121.344 38.5004 118.188 37.5486 114.67 38.5076C111.945 39.2511 110.048 40.853 110.027 40.8674C109.403 41.399 108.47 41.3235 107.939 40.6986C107.409 40.0736 107.484 39.1398 108.108 38.6082C108.205 38.5256 110.536 36.5609 113.892 35.645C117.087 34.7722 121.814 34.6968 126.192 39.287C126.759 39.8797 126.737 40.8171 126.145 41.3846C125.859 41.6576 125.489 41.7941 125.123 41.7941L125.12 41.7977Z"
          fill="black"
        />
        <Path
          d="M139.529 34.3408C142.402 33.9072 145.086 35.892 145.519 38.7688L146.304 43.9827C146.736 46.8595 144.755 49.5486 141.883 49.9822L139.53 34.3443L139.529 34.3408Z"
          fill="#BA824E"
        />
        <Path
          d="M63.833 61.7489C60.9608 62.1824 58.2759 60.1976 57.8431 57.3208L57.0586 52.107C56.6258 49.2302 58.6074 46.541 61.4796 46.1075L63.8325 61.7454L63.833 61.7489Z"
          fill="#BA824E"
        />
        <Path
          d="M116.627 64.8269C117.183 64.7874 117.743 64.8592 118.284 64.9921C118.826 65.1286 119.36 65.3369 119.844 65.635C120.332 65.9296 120.773 66.3211 121.096 66.806C121.257 67.043 121.379 67.3088 121.472 67.5782C121.558 67.8512 121.601 68.1313 121.601 68.4115C121.49 68.1565 121.386 67.9086 121.243 67.6823C121.106 67.4561 120.952 67.2441 120.78 67.0502C120.604 66.8598 120.418 66.6802 120.217 66.5186C120.016 66.357 119.801 66.2097 119.579 66.0732C119.134 65.8003 118.654 65.5776 118.162 65.3728C117.664 65.1753 117.155 64.9993 116.627 64.8305V64.8269Z"
          fill="#BA824E"
        />
        <Path
          d="M76.6395 74.0908C76.6395 74.0908 88.423 61.1677 73.5914 51.0641C58.7598 40.9606 76.6395 74.0908 76.6395 74.0908Z"
          fill="#E9A362"
        />
        <Path
          d="M109.071 113.949V194.447H173.497L166.167 112.34C166.167 112.34 145.476 101.454 109.067 113.949H109.071Z"
          fill="black"
        />
        <Path
          d="M71.4746 90.2436C71.4746 90.2436 103.35 103.289 117.97 106.561C132.594 109.833 71.4746 102.822 71.4746 102.822C71.4746 102.822 69.3553 89.7947 71.4746 90.2436Z"
          fill="#FFCED9"
        />
        <Path
          d="M71.4776 94.4936C71.4776 94.4936 100.158 100.614 103.497 102.288L84.0357 101.293L70.5381 99.7268L71.4812 94.4936H71.4776Z"
          fill="#E087AB"
        />
        <Path
          d="M51.509 169.917C51.509 169.917 44.7423 186.96 43.939 192.908C43.1358 198.856 131.72 195.965 131.72 195.965C131.72 195.965 113.901 164.296 103.212 156.42C92.5218 148.543 52.2441 163.244 51.509 169.917Z"
          fill="#C2417D"
        />
        <Path
          d="M70.9666 86.3307L72.1285 88.7551C75.7431 96.2978 71.6085 105.392 63.4863 107.353C57.6232 108.768 51.3657 105.949 48.6511 100.285L47.396 97.6627L70.9666 86.3343V86.3307Z"
          fill="#E9A362"
        />
        <G opacity="0.2">
          <Path
            d="M70.9666 86.3307L72.1392 88.7767C69.4462 92.2786 65.9247 95.2095 61.6969 97.2424C57.469 99.2753 52.983 100.191 48.5686 100.109L47.396 97.6627L70.9666 86.3343V86.3307Z"
            fill="black"
          />
        </G>
        <G opacity="0.2">
          <Path
            d="M76.8209 55.6947L73.5756 48.917C66.6044 34.3705 49.0188 28.1783 34.4956 35.1607C19.9724 42.143 13.7902 59.7569 20.7613 74.3035L24.0066 81.0811C25.3406 83.8611 27.0619 86.3358 29.0736 88.4729C29.6545 88.3508 30.239 88.2287 30.8164 88.0958C36.9735 86.7165 43.1378 85.3696 49.2877 83.9581C55.1508 82.6112 60.978 80.8117 66.9845 80.2263C67.1495 80.2119 67.2894 80.237 67.4005 80.2909C67.7735 80.2514 68.1858 80.4166 68.3544 80.8584C69.3369 83.4337 70.7391 85.8222 71.8794 88.3292C79.7865 79.745 82.1496 66.8219 76.8173 55.6911L76.8209 55.6947Z"
            fill="black"
          />
        </G>
        <Path
          d="M71.0309 48.9114C64.0311 34.3043 46.5342 28.1465 31.9505 35.1576C17.3668 42.1687 11.2189 59.6938 18.2188 74.3009L21.4665 81.0782C28.4663 95.6853 45.9632 101.843 60.5469 94.832C75.1306 87.8209 81.2785 70.2958 74.2786 55.6887L71.0309 48.9114Z"
          fill="#E9A362"
        />
        <Path
          d="M40.2277 73.7473C42.361 72.7391 43.2743 70.1898 42.2678 68.0531C41.2612 65.9165 38.7159 65.0017 36.5827 66.0098C34.4495 67.018 33.5362 69.5674 34.5427 71.704C35.5493 73.8406 38.0945 74.7555 40.2277 73.7473Z"
          fill="#675C00"
        />
        <Path
          d="M39.462 72.1778C40.7265 71.5801 41.268 70.0689 40.6713 68.8024C40.0746 67.5358 38.5659 66.9935 37.3013 67.5912C36.0368 68.1888 35.4954 69.7 36.0921 70.9665C36.6887 72.2331 38.1975 72.7754 39.462 72.1778Z"
          fill="black"
        />
        <Path
          d="M38.6833 69.2305C39.2438 69.2305 39.6981 68.7754 39.6981 68.214C39.6981 67.6526 39.2438 67.1975 38.6833 67.1975C38.1228 67.1975 37.6685 67.6526 37.6685 68.214C37.6685 68.7754 38.1228 69.2305 38.6833 69.2305Z"
          fill="#F1F1F8"
        />
        <Path
          d="M36.6482 68.7193C36.9274 68.7193 37.1538 68.4926 37.1538 68.2129C37.1538 67.9332 36.9274 67.7064 36.6482 67.7064C36.369 67.7064 36.1426 67.9332 36.1426 68.2129C36.1426 68.4926 36.369 68.7193 36.6482 68.7193Z"
          fill="#F1F1F8"
        />
        <Path
          d="M65.0031 61.8353C67.1228 60.7989 68.0023 58.2376 66.9676 56.1145C65.9328 53.9915 63.3756 53.1105 61.256 54.147C59.1363 55.1834 58.2568 57.7446 59.2915 59.8677C60.3263 61.9908 62.8835 62.8717 65.0031 61.8353Z"
          fill="#675C00"
        />
        <Path
          d="M64.2615 60.2545C65.518 59.6401 66.0393 58.1219 65.4259 56.8633C64.8126 55.6048 63.2967 55.0826 62.0402 55.697C60.7837 56.3114 60.2624 57.8296 60.8758 59.0882C61.4891 60.3467 63.005 60.8689 64.2615 60.2545Z"
          fill="black"
        />
        <Path
          d="M61.6501 58.1888C62.2106 58.1888 62.6649 57.7337 62.6649 57.1723C62.6649 56.6109 62.2106 56.1559 61.6501 56.1559C61.0896 56.1559 60.6353 56.6109 60.6353 57.1723C60.6353 57.7337 61.0896 58.1888 61.6501 58.1888Z"
          fill="#F1F1F8"
        />
        <Path
          d="M53.107 65.9478L50.7308 67.0901C49.8202 67.5279 49.4363 68.6221 49.8734 69.5342C50.3105 70.4462 51.4029 70.8307 52.3135 70.3929L54.6897 69.2506C55.6003 68.8128 55.9841 67.7186 55.5471 66.8066C55.11 65.8945 54.0175 65.51 53.107 65.9478Z"
          fill="#BA824E"
        />
        <Path
          d="M67.3157 72.2573C67.3157 72.2573 68.7859 80.2309 60.4916 84.2178C52.1972 88.2046 46.8003 82.1166 46.8003 82.1166C53.0937 77.7993 60.0146 74.4734 67.3157 72.2537V72.2573Z"
          fill="#3B0000"
        />
        <Path
          d="M54.9235 85.926C53.0982 85.926 51.5634 85.495 50.3944 84.9957C47.9631 83.9505 46.5861 82.424 46.5323 82.3594L46.2598 82.0505L46.6004 81.817C52.9333 77.471 59.8685 74.1379 67.2162 71.9074L67.6035 71.7889L67.6788 72.1875C67.6931 72.2737 68.0517 74.2923 67.3704 76.8353C66.7393 79.1843 65.0646 82.424 60.6539 84.5431C58.5382 85.5596 56.6089 85.926 54.9271 85.926H54.9235ZM47.3678 82.169C47.8806 82.6503 49.0389 83.6273 50.71 84.3384C53.7688 85.6422 57.0069 85.4878 60.3383 83.8895C63.6661 82.2875 65.7926 79.8631 66.6604 76.6772C67.1337 74.9352 67.08 73.4267 67.0154 72.7299C60.0335 74.8957 53.4281 78.0672 47.375 82.169H47.3678Z"
          fill="#BA824E"
        />
        <Path
          d="M31.0529 69.215C31.017 69.215 30.9812 69.215 30.9453 69.2078C30.5975 69.1503 30.3644 68.8199 30.4218 68.4715C31.8239 60.0776 40.5629 60.9288 40.6526 60.9396C41.004 60.9791 41.255 61.2916 41.2191 61.6436C41.1833 61.9956 40.8641 62.247 40.5163 62.2111C40.1936 62.1751 32.8817 61.4963 31.6804 68.6834C31.6266 68.9959 31.3577 69.2186 31.0529 69.2186V69.215Z"
          fill="black"
        />
        <Path
          d="M55.2047 55.1845C55.0864 55.1845 54.9681 55.1521 54.8605 55.0839C54.5628 54.8935 54.4768 54.4984 54.6668 54.2003C54.7135 54.1249 59.5223 46.7654 66.9345 50.9175C67.2429 51.0899 67.3504 51.4778 67.1783 51.7867C67.0062 52.0956 66.6189 52.2033 66.3105 52.0309C63.639 50.5331 61.057 50.5511 58.6401 52.0812C56.8005 53.2485 55.7534 54.8684 55.7426 54.8863C55.6207 55.0767 55.4163 55.1809 55.2047 55.1809V55.1845Z"
          fill="black"
        />
        <Path
          d="M23.3012 84.363L22.8163 84.5961C20.4045 85.7555 17.5051 84.7351 16.3475 82.3195L14.9417 79.3858C13.7841 76.9702 14.8029 74.0661 17.2146 72.9067L17.6995 72.6735L23.3027 84.3662L23.3012 84.363Z"
          fill="#BA824E"
        />
        <Path
          d="M31.5186 34.2566C31.5186 34.2566 6.03306 46.8313 15.1343 72.2285C15.1343 72.2285 24.924 60.4943 38.4539 53.282C51.9838 46.0698 62.4297 47.4167 69.1391 44.0764C69.1391 44.0764 56.685 22.156 31.5186 34.253V34.2566Z"
          fill="black"
        />
        <Path
          d="M19.7748 67.9712C19.7748 67.9712 19.9362 63.8766 19.2549 59.0062C19.2549 59.0062 22.1273 65.2415 21.4746 66.4698C21.4746 66.4698 20.0617 68.8009 19.7748 67.9712Z"
          fill="#E9A362"
        />
        <Path
          d="M26.1108 61.4478C26.1108 61.4478 26.1897 58.7037 24.8091 55.8231C24.8091 55.8231 27.1436 59.6771 27.6456 60.2553C28.1512 60.8336 26.8136 62.8701 26.1072 61.4478H26.1108Z"
          fill="#E9A362"
        />
        <Path
          d="M33.2002 48.7188C33.2002 48.7188 35.5705 55.1409 35.3697 55.7084L39.178 53.8766C39.178 53.8766 35.133 52.7488 33.2002 48.7188Z"
          fill="#E9A362"
        />
        <Path
          d="M45.0075 42.3354C45.0075 42.3354 48.0878 49.1238 47.0945 50.0756L50.7953 49.0053C50.7953 49.0053 50.1605 47.1843 45.0039 42.339L45.0075 42.3354Z"
          fill="#E9A362"
        />
        <Path
          d="M55.7417 43.0808C55.7417 43.0808 59.4747 47.4304 61.5689 47.3657C61.5689 47.3657 64.7066 47.513 55.7417 43.0808Z"
          fill="#E9A362"
        />
        <Path
          d="M22.1816 39.7977C26.9627 35.3493 28.0183 28.7022 24.5394 24.9511C21.0605 21.2 14.3644 21.7653 9.58325 26.2138C4.80213 30.6622 3.7465 37.3093 7.22544 41.0604C10.7044 44.8115 17.4005 44.2462 22.1816 39.7977Z"
          fill="black"
        />
        <Path
          d="M24.4288 35.9006L17.7213 42.1415C17.0538 42.7625 17.0148 43.8073 17.6341 44.4751C18.2535 45.1429 19.2967 45.1809 19.9642 44.5598L26.6717 38.319C27.3391 37.698 27.3781 36.6531 26.7588 35.9853C26.1394 35.3175 25.0962 35.2796 24.4288 35.9006Z"
          fill="#C2417D"
        />
        <Path
          d="M8.59096 30.3672C8.59096 30.3672 4.6428 31.6171 3.48811 36.4588C2.33343 41.3005 11.9582 50.3876 18.3054 41.7889C24.6525 33.1903 8.59096 30.3636 8.59096 30.3636V30.3672Z"
          fill="black"
        />
        <Path
          d="M16.5758 29.8167C16.5758 29.8167 21.2556 22.7697 25.1822 26.3866C29.1088 29.9999 27.6278 36.228 27.6278 36.228C27.6278 36.228 30.9413 29.5114 25.1069 25.248C19.2725 20.9847 13.5708 28.1717 13.5708 28.1717L16.5758 29.8167Z"
          fill="black"
        />
        <Path
          d="M6.52898 32.5429C6.52898 32.5429 6.64731 22.7554 13.9233 21.9077C13.9233 21.9077 8.38293 23.3696 7.70518 31.0954L6.52539 32.5429H6.52898Z"
          fill="black"
        />
        <Path
          d="M6.43917 34.8894C6.43917 34.8894 2.42646 36.7392 3.11138 40.7943C3.79631 44.8457 9.97853 47.6473 9.97853 47.6473C9.97853 47.6473 1.18571 42.1699 5.17332 38.1867L6.43558 34.893L6.43917 34.8894Z"
          fill="black"
        />
        <Path
          d="M9.6427 33.6577C9.6427 33.6577 1.09731 33.417 1.65672 41.0172C2.10497 47.1052 10.7436 46.6131 10.9157 52.9633C10.9157 52.9633 10.8619 50.8262 6.43325 48.5598C2.00098 46.2934 -1.022 43.3626 0.322741 38.3449C1.67107 33.3272 7.25444 31.6463 7.25444 31.6463L9.6427 33.6613V33.6577Z"
          fill="black"
        />
        <Path
          d="M17.2583 23.0883C17.2583 23.0883 22.0563 19.4966 25.9866 23.1099C29.9168 26.7232 30.7201 29.4637 34.5355 27.948C34.5355 27.948 32.4808 30.5843 29.4219 27.9085C26.3631 25.2326 24.0968 21.9713 20.9411 23.5193L17.2583 23.0883Z"
          fill="black"
        />
        <G opacity="0.7">
          <Path
            d="M49.6978 83.9585C49.6978 83.9585 60.3911 75.9848 66.0247 78.5278C66.0247 78.5278 63.6616 83.8399 56.9235 85.0001C54.607 85.3988 52.4697 85.3449 49.6978 83.9585Z"
            fill="#E5454B"
          />
        </G>
        <Path
          d="M54.1108 78.0865C54.1108 78.0865 54.6667 78.7905 55.868 78.2625C57.0693 77.7309 56.4883 76.8689 56.4883 76.8689L54.1108 78.0865Z"
          fill="white"
        />
        <Path
          d="M56.6729 76.7485C56.6729 76.7485 57.1426 77.492 58.3905 77.0395C59.6385 76.5833 59.0755 75.6135 59.0755 75.6135L56.6729 76.7521V76.7485Z"
          fill="white"
        />
        <Path
          d="M43.9842 105.542C43.9842 105.542 43.1092 160.873 48.9759 167.797C54.8425 174.722 64.686 162.482 86.747 162.482C108.808 162.482 133.974 122.721 133.974 122.721V108.412C133.974 108.412 43.9842 86.5055 43.9842 105.542Z"
          fill="#FFCED9"
        />
        <Path
          d="M78.4336 124.549L102.191 126.959L137.15 116.59C137.15 116.59 125.951 156.703 107.824 142.96C96.9086 134.685 78.4336 124.549 78.4336 124.549Z"
          fill="#E087AB"
        />
        <Path
          d="M21.4669 81.0829C21.4669 81.0829 23.1164 81.575 19.7205 65.0889L15.9624 67.6426C15.9624 67.6426 18.1212 76.8519 21.4669 81.0829Z"
          fill="black"
        />
        <Path
          d="M14.6829 53.5382C14.6829 53.5382 11.911 57.9488 13.27 73.7561C13.27 73.7561 13.2449 61.0054 15.124 58.8827C17.0031 56.76 14.6829 53.5382 14.6829 53.5382Z"
          fill="black"
        />
        <Path
          d="M28.0762 36.4343C28.0762 36.4343 35.2696 28.6438 49.3123 29.2723C49.3123 29.2723 36.4996 30.1739 30.8661 36.1972L28.0762 36.4379V36.4343Z"
          fill="black"
        />
        <G opacity="0.2">
          <Path
            d="M54.3955 32.0637C54.3955 32.0637 65.6232 30.7024 73.0067 37.7997C73.0067 37.7997 62.6361 31.5249 56.0415 33.7482L54.3955 32.0673V32.0637Z"
            fill="white"
          />
        </G>
        <Path
          d="M54.7412 32.1401C54.7412 32.1401 66.0478 31.9426 72.6675 39.7618C72.6675 39.7618 62.9961 32.4526 56.2079 33.9827L54.7448 32.1401H54.7412Z"
          fill="black"
        />
        <Path
          d="M62.6121 55.9694C62.8913 55.9694 63.1177 55.7426 63.1177 55.4629C63.1177 55.1832 62.8913 54.9565 62.6121 54.9565C62.3328 54.9565 62.1064 55.1832 62.1064 55.4629C62.1064 55.7426 62.3328 55.9694 62.6121 55.9694Z"
          fill="#F1F1F8"
        />
        <Path
          d="M138.661 115.563L102.191 126.96C102.191 126.96 117.435 144.48 106.337 154.16C95.2346 163.844 135.509 140.569 135.509 140.569L138.661 115.563Z"
          fill="black"
        />
        <Path
          d="M110.795 175.169L114.187 177.04C107.833 173.366 102.145 163.445 105.699 157.278L137.6 101.937C141.153 95.7733 149.261 93.7332 155.616 97.4112L155.644 97.5512C165.488 102.652 170.067 116.587 162.942 127.636L133.924 169.957C129.173 177.435 117.149 178.843 110.795 175.169Z"
          fill="#434343"
        />
        <Path
          d="M133.114 97.3911L139.361 94.5213C139.361 94.5213 145.167 88.0777 146.58 86.975C151.933 82.805 158.349 91.849 158.195 97.348C158.037 102.851 153.095 105.297 151.252 105.174C149.409 105.052 138.784 109.538 138.784 109.538L133.114 97.3911Z"
          fill="#E9A362"
        />
        <Path
          d="M135.115 94.4941L103.508 102.13L102.231 127.032C102.231 127.032 134.957 120.437 143.94 112C146.655 109.45 135.115 94.4941 135.115 94.4941Z"
          fill="#FFCED9"
        />
        <Path
          d="M58.7715 136.36C58.7715 136.36 57.563 136.813 57.563 146.672C57.563 156.531 57.5415 164.555 60.69 164.282C63.842 164.009 64.9035 139.14 58.7715 136.36Z"
          fill="black"
        />
        <Path
          d="M67.1305 140.497L57.8392 138.317C57.8392 138.317 48.1642 131.148 45.9409 130.038C37.5103 125.839 31.2599 139.915 32.9884 147.361C34.7132 154.807 42.1111 156.771 44.589 156.096C47.0669 155.421 62.7591 158.596 62.7591 158.596L67.134 140.501L67.1305 140.497Z"
          fill="#E9A362"
        />
        <Path
          d="M117.05 151.313L58.7739 136.364C58.7739 136.364 65.5407 154.154 60.6924 164.286L117.978 177.443L117.05 151.316V151.313Z"
          fill="#434343"
        />
        <Path
          d="M21.3461 85.5683C21.9264 85.5683 22.3968 85.0972 22.3968 84.5159C22.3968 83.9347 21.9264 83.4636 21.3461 83.4636C20.7658 83.4636 20.2954 83.9347 20.2954 84.5159C20.2954 85.0972 20.7658 85.5683 21.3461 85.5683Z"
          fill="#FADD00"
        />
        <Path
          d="M124.277 133.518L239.369 123.519C231.979 109.421 216.638 100.399 199.809 101.871L160.948 104.68C137.226 110.416 134.752 110.258 128.082 119.758C125.737 123.109 125.529 129.567 124.277 133.518Z"
          fill="black"
        />
        <Path
          d="M159.878 96.3704L160.229 100.386C161.1 110.367 169.886 117.752 179.851 116.879C189.817 116.006 197.19 107.207 196.318 97.2252L195.967 93.2097L159.874 96.3704H159.878Z"
          fill="#E9A362"
        />
        <G opacity="0.2">
          <Path
            d="M159.879 96.371L160.205 100.117C165.885 102.387 172.15 103.393 178.626 102.825C185.103 102.258 191.098 100.178 196.298 96.9565L195.972 93.2103L159.879 96.371Z"
            fill="black"
          />
        </G>
        <Path
          d="M181.271 121.433L200.502 201.235L136.632 216.676L124.282 133.52C124.282 133.52 142.194 117.766 181.274 121.433H181.271Z"
          fill="#5952C1"
        />
        <Path
          d="M216.248 60.1549C216.248 60.1549 227.913 103.967 221.659 117.303L196.131 115.568L196.371 94.0142L214.692 57.0408L216.248 60.1549Z"
          fill="black"
        />
        <Path
          d="M135.827 67.7971C135.827 67.7971 132.596 113.024 141.238 124.945L165.985 118.433L161.703 97.3068L136.766 64.4424L135.823 67.7935L135.827 67.7971Z"
          fill="black"
        />
        <Path
          d="M181.134 99.7059L177.547 100.047C156.98 102.001 138.695 86.8614 136.744 66.2619L135.624 54.4407C133.579 32.8471 149.422 13.6557 170.977 11.6072C192.536 9.55843 211.697 25.427 213.742 47.0169L214.862 58.8382C216.813 79.4377 201.697 97.7518 181.131 99.7063L181.134 99.7059Z"
          fill="#E9A362"
        />
        <Path
          d="M193.735 57.3836C196.31 57.1216 198.186 54.8182 197.924 52.2388C197.663 49.6594 195.363 47.7808 192.788 48.0428C190.213 48.3048 188.337 50.6082 188.599 53.1876C188.86 55.767 191.16 57.6456 193.735 57.3836Z"
          fill="#675C00"
        />
        <Path
          d="M193.568 55.4711C195.095 55.3157 196.207 53.9499 196.052 52.4204C195.897 50.8909 194.534 49.777 193.007 49.9324C191.48 50.0877 190.367 51.4535 190.523 52.983C190.678 54.5125 192.041 55.6264 193.568 55.4711Z"
          fill="black"
        />
        <Path
          d="M192.054 52.4166C192.668 52.4166 193.165 51.9181 193.165 51.3032C193.165 50.6882 192.668 50.1897 192.054 50.1897C191.44 50.1897 190.942 50.6882 190.942 51.3032C190.942 51.9181 191.44 52.4166 192.054 52.4166Z"
          fill="#F1F1F8"
        />
        <Path
          d="M157.225 60.854C159.804 60.6263 161.71 58.348 161.482 55.7654C161.255 53.1827 158.98 51.2737 156.402 51.5015C153.823 51.7293 151.917 54.0076 152.145 56.5902C152.372 59.1728 154.647 61.0818 157.225 60.854Z"
          fill="#675C00"
        />
        <Path
          d="M157.031 58.9473C158.56 58.8122 159.69 57.4613 159.555 55.9299C159.42 54.3985 158.072 53.2665 156.543 53.4016C155.014 53.5366 153.884 54.8876 154.019 56.419C154.153 57.9504 155.502 59.0823 157.031 58.9473Z"
          fill="black"
        />
        <Path
          d="M157.739 55.6804C158.353 55.6804 158.85 55.1819 158.85 54.567C158.85 53.952 158.353 53.4535 157.739 53.4535C157.125 53.4535 156.627 53.952 156.627 54.567C156.627 55.1819 157.125 55.6804 157.739 55.6804Z"
          fill="#F1F1F8"
        />
        <Path
          d="M171.683 63.7381C171.903 66.0605 173.96 67.7646 176.278 67.5443C178.595 67.3241 180.296 65.2629 180.076 62.9405L179.586 57.7701C179.366 55.4477 177.309 53.7436 174.991 53.9639C172.674 54.1841 170.973 56.2453 171.193 58.5677L171.683 63.7381Z"
          fill="#BA824E"
        />
        <Path
          d="M184.842 44.248C184.221 44.248 183.648 43.8493 183.45 43.2279C183.206 42.4593 183.63 41.6368 184.401 41.3925L189.661 39.7188C193.505 38.4976 197.701 38.9753 201.172 41.0298C201.864 41.4392 202.097 42.3372 201.685 43.0304C201.276 43.7236 200.38 43.957 199.688 43.544C196.93 41.9097 193.599 41.529 190.547 42.5024L185.286 44.1761C185.139 44.2228 184.989 44.2444 184.845 44.2444L184.842 44.248Z"
          fill="black"
        />
        <Path
          d="M148.008 48.7315C147.603 48.7315 147.202 48.5662 146.915 48.2358C146.38 47.6324 146.438 46.7057 147.044 46.1741C150.067 43.4983 154.097 42.2412 158.103 42.7189L163.586 43.3726C164.386 43.4696 164.959 44.1951 164.863 44.996C164.766 45.797 164.041 46.3645 163.242 46.2747L157.759 45.621C154.574 45.2403 151.379 46.2424 148.977 48.3651C148.701 48.6093 148.353 48.7315 148.012 48.7315H148.008Z"
          fill="black"
        />
        <Path
          d="M159.837 25.3248C159.837 25.3248 167.672 32.4113 193.541 33.8659C219.41 35.3242 214.691 57.0399 214.691 57.0399C214.691 57.0399 221.519 26.6429 201.355 13.2242C179.965 -1.0099 155.541 10.2825 155.541 10.2825L159.833 25.3248H159.837Z"
          fill="black"
        />
        <Path
          d="M157.368 9.5744L159.839 25.3242C159.839 25.3242 149.676 33.5924 135.734 52.1437L132.492 56.9639C132.492 56.9639 129.699 20.0336 157.368 9.5744Z"
          fill="black"
        />
        <Path
          d="M141.049 27.6088C142.734 25.0731 144.707 22.7277 146.937 20.648C155.343 12.8037 167.216 8.89585 178.63 10.0272C188.853 11.0401 198.467 15.8602 205.223 23.6579C211.036 30.3673 214.522 39.0557 214.963 47.9273C211.728 38.7253 206.188 30.2236 198.511 24.2254C190.833 18.2236 180.95 14.8689 171.257 15.8028C161.579 16.733 152.528 21.8944 146.127 29.2287C139.729 36.563 135.889 45.9411 134.44 55.5706C133.149 45.8621 135.634 35.7477 141.049 27.6088Z"
          fill="#7664EF"
        />
        <Path
          d="M137.202 67.6657C134.35 67.9368 131.817 65.8396 131.546 62.9826L131.056 57.8086C130.786 54.9517 132.879 52.4148 135.732 52.1437L137.202 67.6622L137.202 67.6657Z"
          fill="#BA824E"
        />
        <Path
          d="M213.208 44.7847C216.061 44.5136 218.594 46.6108 218.864 49.4678L219.354 54.6418C219.625 57.4988 217.531 60.0357 214.679 60.3067L213.209 44.7882L213.208 44.7847Z"
          fill="#BA824E"
        />
        <Path
          d="M176.934 83.4106C189.632 82.3008 192.214 77.1646 192.214 77.1646L159.994 77.2185C159.994 77.2185 164.236 84.5205 176.934 83.4106Z"
          fill="white"
        />
        <Path
          d="M176.978 83.911C170.279 84.4965 165.915 82.7365 163.433 81.1561C160.715 79.4249 159.607 77.55 159.561 77.471L159.123 76.7132L193.036 76.6557L192.666 77.3884C192.577 77.568 190.708 81.0915 182.945 82.9664C181.252 83.3758 179.276 83.7063 176.978 83.9074V83.911ZM160.988 77.7224C161.54 78.3869 162.523 79.3926 164.025 80.3408C166.37 81.8206 170.505 83.4692 176.892 82.9089C183.278 82.3486 186.99 80.7503 188.976 79.5039C190.084 78.8107 190.791 78.1499 191.218 77.6721L160.988 77.7224Z"
          fill="#BA824E"
        />
        <Path
          d="M160.993 73.9047C160.434 73.9981 159.896 74.2028 159.39 74.4686C158.885 74.7344 158.408 75.0684 157.995 75.4779C157.583 75.8873 157.239 76.383 157.034 76.9361C156.93 77.2127 156.873 77.5036 156.848 77.7909C156.83 78.0819 156.851 78.3728 156.92 78.6458C156.97 78.3656 157.013 78.0962 157.099 77.8376C157.181 77.579 157.282 77.3348 157.407 77.1013C157.536 76.8715 157.68 76.6488 157.838 76.4404C157.999 76.2321 158.175 76.0346 158.365 75.8478C158.741 75.4707 159.164 75.1366 159.602 74.8134C160.047 74.4973 160.509 74.1992 160.993 73.9047Z"
          fill="#BA824E"
        />
        <Path
          d="M205.401 73.1062C205.401 73.1062 188.977 66.1777 198.405 50.4782C207.832 34.7751 205.401 73.1062 205.401 73.1062Z"
          fill="#E9A362"
        />
        <Path
          d="M212.876 88.9197C212.876 88.9197 184.392 109.493 170.676 116.238C156.96 122.987 215.877 101.387 215.877 101.387C215.877 101.387 214.866 87.9643 212.872 88.9161L212.876 88.9197Z"
          fill="#E5454B"
        />
        <G opacity="0.2">
          <Path
            d="M213.889 93.1341C213.889 93.1341 186.919 106.075 184.007 108.535L203.063 102.886L216.073 98.0979L213.889 93.1377V93.1341Z"
            fill="black"
          />
        </G>
        <Path
          d="M251.705 163.122C251.705 163.122 257.869 187.197 257.869 208.227C257.869 214.347 178.408 208.169 178.408 208.169C178.408 208.169 188.506 172.503 197.223 162.134C205.941 151.761 249.381 156.685 251.705 163.125V163.122Z"
          fill="black"
        />
        <Path
          d="M212.445 84.9188L211.871 87.6019C210.089 95.9455 216.365 103.973 224.881 103.969C231.031 103.969 236.561 99.6737 237.898 93.4061L238.519 90.504L212.445 84.9188Z"
          fill="#E9A362"
        />
        <G opacity="0.2">
          <Path
            d="M212.445 84.9189L211.867 87.6235C215.374 90.4466 219.563 92.5118 224.242 93.5139C228.922 94.516 233.587 94.3508 237.941 93.2086L238.518 90.504L212.445 84.9189Z"
            fill="black"
          />
        </G>
        <G opacity="0.2">
          <Path
            d="M199.324 55.9511L200.924 48.4551C204.359 32.3641 220.313 22.0127 236.382 25.4536C252.447 28.8944 262.782 44.8741 259.346 60.9687L257.747 68.4647C257.091 71.5428 255.976 74.4054 254.491 77.0058C253.885 77.0238 253.279 77.0418 252.673 77.0525C246.24 77.1603 239.806 77.304 233.37 77.3794C227.234 77.4476 221.03 77.0633 214.934 77.9217C214.765 77.9469 214.636 78.0044 214.536 78.087C214.156 78.1373 213.79 78.3994 213.726 78.8772C213.367 81.6679 212.549 84.3689 212.015 87.1274C202.125 80.5114 196.696 68.2671 199.321 55.9546L199.324 55.9511Z"
            fill="black"
          />
        </G>
        <Path
          d="M224.814 90.8461L224.818 90.8469C240.951 94.3021 256.825 84.004 260.275 67.8454L261.876 60.3465C265.326 44.1879 255.044 28.2877 238.911 24.8324L238.908 24.8317C222.775 21.3764 206.901 31.6745 203.451 47.8332L201.85 55.332C198.4 71.4906 208.682 87.3909 224.814 90.8461Z"
          fill="#E9A362"
        />
        <Path
          d="M245.076 61.6967C245.564 59.3366 244.049 57.0274 241.692 56.5391C239.336 56.0508 237.031 57.5682 236.543 59.9283C236.055 62.2885 237.57 64.5977 239.927 65.086C242.283 65.5743 244.589 64.0569 245.076 61.6967Z"
          fill="#673400"
        />
        <Path
          d="M243.362 61.338C243.65 59.9394 242.753 58.571 241.356 58.2816C239.96 57.9922 238.594 58.8915 238.305 60.2901C238.016 61.6887 238.914 63.0571 240.31 63.3465C241.706 63.6359 243.073 62.7366 243.362 61.338Z"
          fill="black"
        />
        <Path
          d="M240.121 60.253C240.692 60.253 241.154 59.7898 241.154 59.2185C241.154 58.6472 240.692 58.1841 240.121 58.1841C239.551 58.1841 239.088 58.6472 239.088 59.2185C239.088 59.7898 239.551 60.253 240.121 60.253Z"
          fill="#F1F1F8"
        />
        <Path
          d="M242.141 59.2505C242.427 59.2505 242.658 59.0189 242.658 58.7333C242.658 58.4476 242.427 58.216 242.141 58.216C241.856 58.216 241.625 58.4476 241.625 58.7333C241.625 59.0189 241.856 59.2505 242.141 59.2505Z"
          fill="#F1F1F8"
        />
        <Path
          d="M217.708 55.893C218.227 53.5396 216.743 51.2105 214.393 50.6909C212.043 50.1713 209.718 51.6579 209.199 54.0114C208.68 56.3649 210.165 58.694 212.514 59.2136C214.864 59.7332 217.189 58.2465 217.708 55.893Z"
          fill="#673400"
        />
        <Path
          d="M215.955 55.5072C216.263 54.1125 215.383 52.7323 213.991 52.4244C212.598 52.1165 211.22 52.9975 210.913 54.3921C210.606 55.7868 211.485 57.167 212.878 57.4749C214.27 57.7828 215.648 56.9018 215.955 55.5072Z"
          fill="black"
        />
        <Path
          d="M214.717 54.8118C215.287 54.8118 215.75 54.3487 215.75 53.7774C215.75 53.2061 215.287 52.743 214.717 52.743C214.146 52.743 213.684 53.2061 213.684 53.7774C213.684 54.3487 214.146 54.8118 214.717 54.8118Z"
          fill="#F1F1F8"
        />
        <Path
          d="M224.507 64.0869L227.137 64.6501C228.144 64.8658 229.135 64.223 229.35 63.2143C229.566 62.2056 228.924 61.213 227.917 60.9973L225.287 60.434C224.28 60.2183 223.289 60.8612 223.073 61.8699C222.858 62.8786 223.5 63.8712 224.507 64.0869Z"
          fill="#BA824E"
        />
        <Path
          d="M212.703 70.0918C212.703 70.0918 213.151 78.3492 222.328 80.3139C231.501 82.2786 235.399 74.9514 235.399 74.9514C228.126 72.1786 220.474 70.5408 212.707 70.0918H212.703Z"
          fill="#3B0000"
        />
        <Path
          d="M222.25 80.675C217.373 79.6298 214.938 76.8174 213.751 74.6408C212.467 72.2847 212.338 70.1979 212.335 70.1081L212.313 69.695L212.725 69.7202C220.539 70.1691 228.213 71.8141 235.529 74.6049L235.919 74.7558L235.722 75.1257C235.679 75.2047 234.686 77.0509 232.524 78.6672C231.484 79.443 230.067 80.2404 228.256 80.675C226.589 81.0772 224.588 81.1778 222.25 80.675ZM213.123 70.4888C213.224 71.1928 213.532 72.7013 214.418 74.3176C216.035 77.27 218.725 79.1628 222.408 79.953C226.09 80.7432 229.339 80.1147 232.057 78.0925C233.542 76.9862 234.46 75.7435 234.851 75.1401C227.869 72.5253 220.564 70.9593 213.123 70.4888Z"
          fill="#BA824E"
        />
        <Path
          d="M247.933 58.3852C247.969 58.378 248.002 58.3637 248.037 58.3493C248.367 58.2092 248.521 57.8249 248.382 57.4945C244.986 49.51 236.526 52.4481 236.44 52.4768C236.103 52.5989 235.928 52.9725 236.049 53.3101C236.171 53.6477 236.544 53.8237 236.881 53.7016C237.193 53.5902 244.279 51.1622 247.188 58.0009C247.313 58.299 247.636 58.4535 247.937 58.378L247.933 58.3852Z"
          fill="black"
        />
        <Path
          d="M220.631 50.2624C220.746 50.2336 220.857 50.1726 220.947 50.0792C221.194 49.8206 221.187 49.4075 220.929 49.1561C220.864 49.0951 214.341 42.946 207.983 48.8436C207.718 49.0879 207.704 49.5009 207.947 49.7631C208.191 50.0253 208.604 50.0433 208.865 49.799C211.157 47.6727 213.721 47.0729 216.482 48.0104C218.587 48.7251 220.011 50.0828 220.025 50.0972C220.19 50.2588 220.419 50.3127 220.631 50.2624Z"
          fill="black"
        />
        <Path
          d="M261.985 58.6166L262.521 58.7315C265.19 59.3031 266.894 61.9379 266.323 64.6108L265.63 67.8562C265.06 70.529 262.429 72.2355 259.76 71.664L259.224 71.5491L261.985 58.6166Z"
          fill="#BA824E"
        />
        <Path
          d="M239.115 23.8369C239.115 23.8369 266.06 27.2742 263.919 53.6699C263.879 54.1692 263.345 54.4745 262.897 54.2446C259.831 52.6786 249.439 47.6286 236.784 44.3601C221.647 40.4523 211.614 44.2883 204.162 42.5894C204.162 42.5894 211.273 17.8746 239.111 23.8369H239.115Z"
          fill="black"
        />
        <Path
          d="M247.28 50.9903C247.28 50.9903 247.434 46.8131 248.503 41.914C248.503 41.914 245.1 48.0307 245.666 49.331C245.666 49.331 246.921 51.8129 247.28 50.9867V50.9903Z"
          fill="#E9A362"
        />
        <Path
          d="M246.072 47.7492C246.072 47.7492 245.337 45.0482 246.018 41.8624C246.018 41.8624 244.627 46.2407 244.264 46.9339C243.902 47.6271 245.717 49.326 246.072 47.7492Z"
          fill="#E9A362"
        />
        <Path
          d="M227.605 36.1248C227.605 36.1248 226.791 43.0604 227.124 43.574L222.911 42.6725C222.911 42.6725 226.654 40.5857 227.605 36.1248Z"
          fill="#E9A362"
        />
        <Path
          d="M223.825 34.7329C223.825 34.7329 222.394 42.2001 223.603 42.9041L219.378 42.8143C219.378 42.8143 219.873 40.7742 223.821 34.7329H223.825Z"
          fill="#E9A362"
        />
        <Path
          d="M217.206 38.3936C217.206 38.3936 224.629 46.6654 212.451 44.0399C212.451 44.0399 209.378 44.9378 217.206 38.3936Z"
          fill="#E9A362"
        />
        <G opacity="0.7">
          <Path
            d="M232.962 77.4721C232.962 77.4721 220.454 72.1312 215.48 76.0031C215.48 76.0031 219.092 80.7011 226.048 80.2377C228.44 80.0797 230.545 79.5122 232.962 77.4721Z"
            fill="#E5454B"
          />
        </G>
        <Path
          d="M227.19 72.704C227.19 72.704 226.645 74.2377 225.329 74.0006C224.009 73.7636 224.543 72.0647 224.543 72.0647L227.194 72.7004L227.19 72.704Z"
          fill="white"
        />
        <Path
          d="M224.329 71.9969C224.329 71.9969 223.874 73.4551 222.529 73.3007C221.184 73.1462 221.83 71.5479 221.83 71.5479L224.329 71.9969Z"
          fill="white"
        />
        <Path
          d="M243.788 97.4975C243.788 97.4975 257.873 152.142 253.71 160.414C249.547 168.682 236.867 158.909 214.996 164.196C193.125 169.483 158.678 136.098 158.678 136.098L155.261 121.911C155.261 121.911 239.244 78.6264 243.791 97.4975H243.788Z"
          fill="#E5454B"
        />
        <G opacity="0.2">
          <Path
            d="M214.171 124.596L191.192 132.677L154.06 130.777C154.06 130.777 174.747 167.862 189.432 149.892C198.275 139.07 214.171 124.596 214.171 124.596Z"
            fill="black"
          />
        </G>
        <Path
          d="M260.273 67.8508C260.273 67.8508 258.756 68.7343 258.182 51.5766L263.819 54.0261C263.819 54.0261 262.579 62.8546 260.273 67.8508Z"
          fill="black"
        />
        <Path
          d="M240.159 23.8212C240.159 23.8212 245.441 23.322 259.182 31.8308C259.182 31.8308 247.607 25.9439 244.813 26.6803C242.02 27.4166 240.159 23.8212 240.159 23.8212Z"
          fill="black"
        />
        <Path
          d="M253.142 30.4269C253.142 30.4269 258.03 32.4994 266.091 46.5215C266.091 46.5215 258.693 35.8397 255.882 35.1573C253.074 34.4784 253.138 30.4269 253.138 30.4269H253.142Z"
          fill="black"
        />
        <Path
          d="M243.053 25.1743C243.053 25.1743 234.059 19.1761 220.289 23.163C220.289 23.163 233.206 20.9864 240.231 25.6053L243.056 25.1743H243.053Z"
          fill="black"
        />
        <Path
          d="M247.617 28.6024C247.617 28.6024 240.326 19.6517 229.891 19.6338C229.891 19.6338 241.944 22.3707 245.221 28.6742L247.617 28.6024Z"
          fill="black"
        />
        <Path
          d="M213.355 52.8356C213.64 52.8356 213.872 52.604 213.872 52.3183C213.872 52.0327 213.64 51.8011 213.355 51.8011C213.07 51.8011 212.839 52.0327 212.839 52.3183C212.839 52.604 213.07 52.8356 213.355 52.8356Z"
          fill="#F1F1F8"
        />
        <Path
          d="M152.318 130.121L191.194 132.678C191.194 132.678 180.264 153.701 193.586 160.64C206.904 167.579 161.42 154.157 161.42 154.157L152.318 130.125V130.121Z"
          fill="#5952C1"
        />
        <Path
          d="M219.497 71.163L218.008 70.9546C218.008 70.9546 217.632 71.8993 218.331 71.8993C219.03 71.8993 219.497 71.163 219.497 71.163Z"
          fill="white"
        />
        <Path
          d="M217.469 70.8888L216.146 70.738C216.146 70.738 215.877 71.5461 216.58 71.5461C217.283 71.5461 217.469 70.8852 217.469 70.8852V70.8888Z"
          fill="white"
        />
        <Path
          d="M227.818 72.8866C227.818 72.8866 227.646 73.702 228.937 74.0252C230.227 74.3521 230.156 73.5511 230.156 73.5511L227.814 72.8866H227.818Z"
          fill="white"
        />
        <Path
          d="M231.073 73.8365C231.073 73.8365 230.925 74.3537 231.277 74.7021C231.628 75.0541 232.604 74.817 232.815 74.4076L231.073 73.8329V73.8365Z"
          fill="white"
        />
        <G opacity="0.8">
          <Path
            d="M218.681 61.7351C219.067 61.7351 219.38 61.5775 219.38 61.3831C219.38 61.1887 219.067 61.0311 218.681 61.0311C218.295 61.0311 217.982 61.1887 217.982 61.3831C217.982 61.5775 218.295 61.7351 218.681 61.7351Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M221.814 63.5722C221.911 63.1976 221.837 62.8546 221.649 62.806C221.461 62.7574 221.23 63.0216 221.134 63.3961C221.037 63.7707 221.111 64.1137 221.299 64.1623C221.487 64.2109 221.718 63.9467 221.814 63.5722Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M234.765 60.0641C234.861 59.6895 234.787 59.3465 234.599 59.2979C234.411 59.2493 234.181 59.5135 234.084 59.888C233.987 60.2626 234.062 60.6056 234.249 60.6542C234.437 60.7028 234.668 60.4386 234.765 60.0641Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M228.035 59.6344C228.132 59.4661 227.94 59.1728 227.605 58.9794C227.271 58.786 226.921 58.7657 226.824 58.934C226.727 59.1024 226.92 59.3957 227.254 59.5891C227.588 59.7825 227.938 59.8028 228.035 59.6344Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M231.628 59.2853C231.822 59.2853 231.98 59.1277 231.98 58.9333C231.98 58.7389 231.822 58.5813 231.628 58.5813C231.434 58.5813 231.277 58.7389 231.277 58.9333C231.277 59.1277 231.434 59.2853 231.628 59.2853Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.8">
          <Path
            d="M240.375 67.6971C240.569 67.6971 240.726 67.5395 240.726 67.3451C240.726 67.1507 240.569 66.9931 240.375 66.9931C240.181 66.9931 240.023 67.1507 240.023 67.3451C240.023 67.5395 240.181 67.6971 240.375 67.6971Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M233.375 67.6971C233.569 67.6971 233.726 67.5395 233.726 67.3451C233.726 67.1507 233.569 66.9931 233.375 66.9931C233.181 66.9931 233.023 67.1507 233.023 67.3451C233.023 67.5395 233.181 67.6971 233.375 67.6971Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.8">
          <Path
            d="M212.384 63.1384C212.578 63.1384 212.736 62.9808 212.736 62.7864C212.736 62.592 212.578 62.4344 212.384 62.4344C212.19 62.4344 212.033 62.592 212.033 62.7864C212.033 62.9808 212.19 63.1384 212.384 63.1384Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M223.577 56.8316C223.772 56.8316 223.929 56.674 223.929 56.4796C223.929 56.2852 223.772 56.1276 223.577 56.1276C223.383 56.1276 223.226 56.2852 223.226 56.4796C223.226 56.674 223.383 56.8316 223.577 56.8316Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M215.356 63.4912C215.453 63.4912 215.532 63.3336 215.532 63.1392C215.532 62.9448 215.453 62.7872 215.356 62.7872C215.259 62.7872 215.18 62.9448 215.18 63.1392C215.18 63.3336 215.259 63.4912 215.356 63.4912Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.8">
          <Path
            d="M219.909 56.8316C220.006 56.8316 220.085 56.674 220.085 56.4796C220.085 56.2852 220.006 56.1276 219.909 56.1276C219.812 56.1276 219.733 56.2852 219.733 56.4796C219.733 56.674 219.812 56.8316 219.909 56.8316Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M221.25 59.1228C221.303 59.0411 221.213 58.8895 221.05 58.7844C220.886 58.6793 220.711 58.6603 220.659 58.7421C220.607 58.8238 220.696 58.9754 220.86 59.0805C221.023 59.1856 221.198 59.2046 221.25 59.1228Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.8">
          <Path
            d="M234.026 62.9075C234.163 62.7701 234.219 62.6029 234.15 62.5342C234.081 62.4655 233.915 62.5212 233.777 62.6586C233.64 62.7961 233.584 62.9633 233.653 63.032C233.722 63.1007 233.889 63.045 234.026 62.9075Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M234.778 65.5419C234.862 65.4933 234.852 65.3174 234.754 65.149C234.657 64.9807 234.511 64.8836 234.427 64.9322C234.343 64.9808 234.353 65.1567 234.45 65.325C234.547 65.4934 234.694 65.5905 234.778 65.5419Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M226.031 57.1339C226.115 57.0853 226.105 56.9094 226.008 56.741C225.911 56.5727 225.764 56.4756 225.68 56.5242C225.596 56.5728 225.607 56.7487 225.704 56.917C225.801 57.0854 225.947 57.1825 226.031 57.1339Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M219.208 64.5385C219.305 64.5385 219.384 64.4597 219.384 64.3625C219.384 64.2653 219.305 64.1866 219.208 64.1866C219.111 64.1866 219.033 64.2653 219.033 64.3625C219.033 64.4597 219.111 64.5385 219.208 64.5385Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M218.503 59.2822C218.6 59.2822 218.679 59.2035 218.679 59.1063C218.679 59.0091 218.6 58.9303 218.503 58.9303C218.406 58.9303 218.328 59.0091 218.328 59.1063C218.328 59.2035 218.406 59.2822 218.503 59.2822Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M215.011 60.6855C215.108 60.6855 215.186 60.6067 215.186 60.5095C215.186 60.4123 215.108 60.3335 215.011 60.3335C214.914 60.3335 214.835 60.4123 214.835 60.5095C214.835 60.6067 214.914 60.6855 215.011 60.6855Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M223.402 59.2822C223.499 59.2822 223.577 59.2035 223.577 59.1063C223.577 59.0091 223.499 58.9303 223.402 58.9303C223.305 58.9303 223.226 59.0091 223.226 59.1063C223.226 59.2035 223.305 59.2822 223.402 59.2822Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M229.006 57.879C229.103 57.879 229.181 57.8002 229.181 57.703C229.181 57.6058 229.103 57.527 229.006 57.527C228.909 57.527 228.83 57.6058 228.83 57.703C228.83 57.8002 228.909 57.879 229.006 57.879Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.8">
          <Path
            d="M227.605 55.778C227.702 55.778 227.781 55.6992 227.781 55.602C227.781 55.5048 227.702 55.426 227.605 55.426C227.508 55.426 227.429 55.5048 227.429 55.602C227.429 55.6992 227.508 55.778 227.605 55.778Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.8">
          <Path
            d="M231.453 65.2403C231.55 65.2403 231.628 65.1615 231.628 65.0643C231.628 64.9671 231.55 64.8883 231.453 64.8883C231.356 64.8883 231.277 64.9671 231.277 65.0643C231.277 65.1615 231.356 65.2403 231.453 65.2403Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M237.052 65.2402C237.149 65.2402 237.228 65.1614 237.228 65.0642C237.228 64.967 237.149 64.8882 237.052 64.8882C236.955 64.8882 236.876 64.967 236.876 65.0642C236.876 65.1614 236.955 65.2402 237.052 65.2402Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M231.453 61.736C231.55 61.736 231.628 61.6572 231.628 61.56C231.628 61.4628 231.55 61.384 231.453 61.384C231.356 61.384 231.277 61.4628 231.277 61.56C231.277 61.6572 231.356 61.736 231.453 61.736Z"
            fill="#7B3400"
          />
        </G>
        <G opacity="0.4">
          <Path
            d="M237.752 68.3956C237.849 68.3956 237.928 68.3168 237.928 68.2196C237.928 68.1224 237.849 68.0436 237.752 68.0436C237.655 68.0436 237.577 68.1224 237.577 68.2196C237.577 68.3168 237.655 68.3956 237.752 68.3956Z"
            fill="#7B3400"
          />
        </G>
        <Path
          d="M212.383 43.1606L210.852 38.8146C210.852 38.8146 207.836 43.0709 204.161 42.5896C200.485 42.1083 212.731 44.5614 212.38 43.1606H212.383Z"
          fill="#E9A362"
        />
        <Path
          d="M226.799 42.904C226.799 42.904 229.284 40.7813 230.981 38.8166C232.677 36.8555 230.687 41.241 231.856 42.904C233.025 44.567 226.799 42.904 226.799 42.904Z"
          fill="#E9A362"
        />
        <Path
          d="M247.019 47.3663C247.019 47.3663 252.968 47.0143 254.015 44.9132C254.015 44.9132 253.316 48.5731 254.015 50.2469C254.714 51.9207 247.019 47.3663 247.019 47.3663Z"
          fill="#E9A362"
        />
        <Path
          d="M255.942 48.7701C255.942 48.7701 254.02 50.8713 252.27 50.5193C250.52 50.1673 255.77 53.3245 255.77 53.3245L255.945 48.7701H255.942Z"
          fill="#E9A362"
        />
        <Path
          d="M258.18 53.516C258.18 53.516 258.571 51.5728 257.52 49.4716C257.52 49.4716 256.821 51.2244 255.422 50.8724C254.024 50.5204 258.18 53.516 258.18 53.516Z"
          fill="#E9A362"
        />
        <Path
          d="M231.567 43.3379C231.567 43.3379 235.48 39.308 236.179 37.2068C236.179 37.2068 234.081 43.8659 234.429 44.2143C234.777 44.5627 231.567 43.3379 231.567 43.3379Z"
          fill="#E9A362"
        />
        <Path
          d="M244.573 47.3672C244.573 47.3672 242.852 43.6425 242.837 41.4731C242.837 41.4731 241.726 46.6129 239.825 46.2897C237.924 45.9664 244.573 47.3672 244.573 47.3672Z"
          fill="#E9A362"
        />
        <Path
          d="M234.399 43.7992C234.399 43.7992 237.921 42.8115 238.624 40.3584C238.624 40.3584 241.17 43.9214 241.123 45.6454C241.073 47.3694 234.403 43.8028 234.403 43.8028L234.399 43.7992Z"
          fill="#E9A362"
        />
        <Path
          d="M252.156 26.1783C252.156 26.1783 244.658 22.2489 240.907 23.7682L240.244 25.9987C240.244 25.9987 243.518 24.3177 252.153 26.1783H252.156Z"
          fill="black"
        />
        <Path
          d="M131.174 116.34V116.197C136.593 111.032 145.117 111.107 150.112 116.365L194.958 163.586C199.563 168.435 197.149 178.323 192.498 183.89C186.524 187.92 175.899 188.61 170.007 182.92L131.128 147.915C121.424 138.67 122.636 123.754 131.174 116.34Z"
          fill="#7664EF"
        />
        <Path
          d="M153.481 110.782L146.603 109.435C146.603 109.435 139.305 104.439 137.645 103.684C131.341 100.833 127.142 111.335 128.608 116.751C130.079 122.168 135.562 123.407 137.362 122.843C139.162 122.279 150.766 124.179 150.766 124.179L153.484 110.778L153.481 110.782Z"
          fill="#E9A362"
        />
        <Path
          d="M150.801 108.387H183.961L191.176 132.764C191.176 132.764 157.159 134.068 146.236 127.858C142.937 125.979 150.801 108.387 150.801 108.387Z"
          fill="#E5454B"
        />
        <Path
          d="M236.49 131.6C236.49 131.6 237.799 131.758 240.151 141.531C242.507 151.305 244.447 159.253 241.255 159.738C238.068 160.223 231.071 135.821 236.486 131.597L236.49 131.6Z"
          fill="#5952C1"
        />
        <Path
          d="M229.19 137.701L237.879 133.312C237.879 133.312 245.757 123.888 247.697 122.253C255.052 116.068 264.609 128.528 264.677 136.322C264.745 144.116 257.878 147.837 255.264 147.762C252.646 147.687 237.85 154.593 237.85 154.593L229.19 137.701Z"
          fill="#E9A362"
        />
        <Path
          d="M182.291 160.388L236.493 131.6C236.493 131.6 234.036 150.859 241.262 159.742L192.052 184.299C190.538 185.053 188.971 185.696 187.365 186.217L182.294 160.392L182.291 160.388Z"
          fill="#7664EF"
        />
        <Path
          d="M135.055 68.0436C135.635 68.0436 136.106 67.5724 136.106 66.9912C136.106 66.41 135.635 65.9388 135.055 65.9388C134.475 65.9388 134.004 66.41 134.004 66.9912C134.004 67.5724 134.475 68.0436 135.055 68.0436Z"
          fill="#FADD00"
        />
        <Path
          d="M136.106 70.1448C136.106 70.7267 135.636 71.1972 135.055 71.1972C134.474 71.1972 134.004 70.7267 134.004 70.1448C134.004 69.5629 134.474 68.0436 135.055 68.0436C135.636 68.0436 136.106 69.5665 136.106 70.1448Z"
          fill="#FADD00"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_79_830">
          <Rect fill="white" height="178" width="269" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
