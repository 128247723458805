import type { ReactNode } from 'react'
import { Suspense } from 'react'

import Alert from './Alert'
import Banners from './Banners'

function NotificationsProvider({ children }: { children: ReactNode }) {
  return (
    <>
      {children}
      <Suspense fallback={null}>
        <Banners />
      </Suspense>
      <Alert />
    </>
  )
}

export default NotificationsProvider
