import { Component } from 'react'
import type { ErrorInfo, ReactNode } from 'react'

import AppError from '@/modules/AppError'

import Sentry from '@/utils/sentry'

type Props = {
  children?: ReactNode | ReactNode[]
  fallback?: ReactNode | undefined
}

type State = {
  error: unknown
}

class ErrorBoundary extends Component<Props, State> {
  state = {
    error: undefined,
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error, errorInfo as any)
  }

  static getDerivedStateFromError(error: Error): Record<string, Error> {
    return { error }
  }

  render(): ReactNode {
    if (this.state.error) {
      return this.props.fallback !== undefined ? (
        this.props.fallback
      ) : (
        <AppError error={this.state.error} />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
