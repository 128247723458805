import type { ComponentProps, ReactNode } from 'react'
import type { ViewStyle } from 'react-native'

import { ContentContainer, Wrapper } from './styled'

type Props = {
  style?: ViewStyle
  withPadding?: boolean
  children: ReactNode
  containerStyle?: ViewStyle
} & ComponentProps<typeof Wrapper>

function Content(props: Props) {
  const { children, withPadding, style, containerStyle, ...otherProps } = props

  return (
    <Wrapper style={containerStyle} {...otherProps}>
      <ContentContainer $withPadding={withPadding} style={style}>
        {children}
      </ContentContainer>
    </Wrapper>
  )
}

export default Content
