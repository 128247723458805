import { useEffect } from 'react'

import { identify } from '@/utils/analytics'
import { getCustomer } from '@/utils/api/customer/api'
import Sentry from '@/utils/sentry'
import { useCustomerId } from '@/utils/zustand'

import { enableAnalytics } from './firebase'

type Options = { disabled: boolean }

async function loadCustomer(customerId: string) {
  try {
    const data = await getCustomer(customerId as string)
    if (!data) return

    return identify({
      address:
        Array.isArray(data.addresses) && typeof data.addresses?.[0] === 'object'
          ? {
              city: data.addresses?.[0].city,
              country: 'USA',
              state: data?.addresses?.[0].state,
              street: data?.addresses?.[0].street1,
            }
          : undefined,
      email: data.emails?.[0]?.address,
      firstName: data.givenName,
      lastName: data.familyName,
      phone: data.phoneNumbers?.[0]?.number,
      userId: customerId,
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

export default function useIdentifyCustomer(options: Options) {
  const customerId = useCustomerId()

  useEffect(() => {
    if (options.disabled) return

    async function identify() {
      await enableAnalytics().catch(Sentry.captureException)

      if (customerId) {
        await loadCustomer(customerId)
      }
    }

    void identify()
  }, [customerId, options.disabled])
}
