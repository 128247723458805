export enum ApplicationStatus {
  New = 'NEW',
  Renew = 'RENEW',
  Lead = 'LEAD',
  AccountCreated = 'ACCOUNT_CREATED',
  NotAvailable = 'NOT_AVAILABLE',
  QuotesRateCall1Pending = 'QUOTES_RATE_CALL1_PENDING',
  QuotesRateCall1 = 'QUOTES_RATE_CALL1',
  QuotesRateCall1PendingAnon = 'QUOTES_RATE_CALL1_PENDING_ANON',
  QuotesRateCall1Anon = 'QUOTES_RATE_CALL1_ANON',
  LoanPrequalifiedPending = 'LOAN_PREQUALIFIED_PENDING',
  LoanPrequalifiedApproved = 'LOAN_PREQUALIFIED_APPROVED',
  LoanPrequalifiedManualReview = 'LOAN_PREQUALIFIED_MANUAL_REVIEW',
  LoanPrequalifiedManualReviewAgentLocked = 'LOAN_PREQUALIFIED_MANUAL_REVIEW_AGENT_LOCKED',
  LoanPrequalifiedDeclined = 'LOAN_PREQUALIFIED_DECLINED',
  DriverVehicleDiscovery = 'DRIVER_VEHICLE_DISCOVERY',
  DriverIncidentDiscovery = 'DRIVER_INCIDENT_DISCOVERY',
  QuotesRateCall15Pending = 'QUOTES_RATE_CALL1_5_PENDING',
  QuotesRateCall15 = 'QUOTES_RATE_CALL1_5',
  IssuerQuestions = 'ISSUER_QUESTIONS',
  ProofDocumentationPending = 'PROOF_DOCUMENTATION_PENDING',
  ProofDocumentation = 'PROOF_DOCUMENTATION',
  QuotesFinalPending = 'QUOTES_FINAL_PENDING',
  QuotesFinalAgentLocked = 'QUOTES_FINAL_AGENT_LOCKED',
  QuotesFinalBounced = 'QUOTES_FINAL_BOUNCED',
  QuotesFinal = 'QUOTES_FINAL',
  QuotesFinalAccepted = 'QUOTES_FINAL_ACCEPTED',
  LoanPremiumFinanceSignaturePending = 'LOAN_PREMIUM_FINANCE_SIGNATURE_PENDING',
  LoanPremiumFinanceSigned = 'LOAN_PREMIUM_FINANCE_SIGNED',
  PolicyGenerationPending = 'POLICY_GENERATION_PENDING',
  PolicyGenerationAgentLocked = 'POLICY_GENERATION_AGENT_LOCKED',
  PolicyGenerated = 'POLICY_GENERATED',
  PolicySignaturePending = 'POLICY_SIGNATURE_PENDING',
  PolicySigned = 'POLICY_SIGNED',
  PolicyBound = 'POLICY_BOUND',
  PaymentAccountCreated = 'PAYMENT_ACCOUNT_CREATED',
  LoanDisbursed = 'LOAN_DISBURSED',
  Expired = 'EXPIRED',
  QuotesUnprocessable = 'QUOTES_UNPROCESSABLE',
}
