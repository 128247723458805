import { isDevelopment } from '.'

export const US_PHONE_MASK = (
  isDevelopment ? ['+1', ' '] : ([] as (string | RegExp)[])
).concat([
  `(`,
  /[2-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /[2-9]/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
])

export const US_PHONE_LENGTH = US_PHONE_MASK.length

/**
 * The length of the phone number without the country code.
 */
export const US_PHONE_VALUE_LENGTH = 10

export const CONFIRMATION_CODE_MASK = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]

export const OTP_CODE_LENGTH = 6

export const US_SSN_MASK = [
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const VIN_MASK = [
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  ' ',
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  ' ',
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  ' ',
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
  /[A-HJ-NPR-Za-hj-npr-z\d]/,
]

export const DRIVER_LICENSE_MASK = [
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
]
