export enum PermissionStatus {
  /**
   * User has granted the permission.
   */
  GRANTED = 'granted',
  /**
   * User hasn't granted or denied the permission yet.
   */
  UNDETERMINED = 'undetermined',
  /**
   * User has denied the permission.
   */
  DENIED = 'denied',
}

export function useTrackingPermissions() {
  return [{ granted: true, status: PermissionStatus.GRANTED }, () => undefined]
}
